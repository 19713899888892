.qb-container {
  display: flex;
}

.qb3-logo {
  width: 153px;
  height: 145px;
  margin-top: 1rem;
}

.status-details {
  display: flex;
  gap: 7px;
  margin-top: 2em;
}

.details {
  display: flex;
  margin-top: -4px;
  flex-direction: column;
  gap: 64px;
  color: white;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.qb3-heading1 {
  color: #fff;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.qb3-heading2 {
  color: #3384b1;
  font-family: Nunito;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.detailsField {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.qb3-left-section {
  width: 43%;
  border-radius: 20px 0px 0px 20px;
  background: #3384b1;
  height: 727px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qb3-left-section > span {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.qb-right-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qb-column1,
.qb-column2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.qb3-form {
  display: flex;
  gap: 18px;
  margin-left: 34px;
  flex-wrap: wrap;
  margin-top: 2vw;
}

.qb3-right-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qb3-dropdown-container > .selected-room-type {
  width: 100%;
  height: 100%;
  padding-left: 14px;
  font-size: 1vw;
  display: flex;
  align-items: center;
  font-family: Nunito;
  font-weight: 100;
}

.qb3-dropdown-container {
  width: 386px;
  height: 55px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  position: relative;
  border-radius: 5px;
}

.fieldRow {
  display: flex;
  gap: 3.2vw;
}

.qb3-form input {
  width: 386px;
  height: 55px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.qb3-textarea {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 41vw;
  height: 9vw;
  margin-top: 4px;
  padding: 4px;
  resize: none;
}

.qb3-dropdown-container svg {
  position: absolute;
  top: 35%;
  right: 2%;
}

.qb3-form > textarea {
  width: 815px;
  background-color: #fff;
  height: 274.05px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-top: -25px;
}

.qb3-image2 {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 14vw;
}

label {
  display: block;
}

.qb3-btn-root {
  display: flex;
  width: -webkit-fill-available;
  justify-content: flex-end;
  gap: 24px;
  margin-right: 3.5em;
  height: 21%;
  align-items: center;
}

.qb3.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.qb3-action-btn {
  width: 136px;
  height: 46px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(102, 163, 166, 1);
  color: rgba(102, 163, 166, 1);
  text-align: center;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
}

.qb3-action-btn:hover,
.qb3-action-btn.next {
  background-color: #3384b1;
  color: white;
  font-family: "Nunito";
  font-weight: 600;
}

.dropdown-container2 {
  font-weight: 300;
  font-family: Nunito;
  width: 386px;
  height: 55px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.qb3.dropdown-icon-container2 {
  display: flex;
  justify-content: flex-end;
  margin-top: -2em;
  margin-right: 1em;
}

.selectHoursMinutes {
  display: flex;
  justify-content: space-between;
}

.selectHoursMinutes input {
  width: 45%;
}

.durationIncrementWrapper {
  display: flex;
}

.durationIncrementWrapper input {
  width: 100%;
  border: none;
}

/* react select */
/* .react-select-container{
  height: 7vh;
} */

.css-13cymwt-control {
  height: 5.5vh;
  border: 1px solid black;
  width: 90%;
}
#ticketNum {
  font-family: Nunito;
  font-weight: 200;
  font-size: 17px;
}
/* .css-1nmdiq5-menu{
  width: 95%;
} */

.durationFieldWrapper {
  display: block;
}

.durationBtnWrapper {
  display: flex;
  flex-direction: row;
}

.durationBtnWrapper button {
  height: 2.7vh;
}

.eact-select__control {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.css-13cymwt-control {
  height: auto;
}

.qb3 btn-container {
  padding-bottom: 5%;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .qb3-left-section {
    width: 31%;
    height: 100%;
  }

  .qb3-logo {
    margin-top: 0;
    width: 11vw;
    height: 10vw;
  }

  .qb3-heading1 {
    font-size: 2vw;
  }

  .qb3-heading2 {
    font-size: 2.5vw;
  }

  .qb3-left-section > span {
    width: 100%;
    height: auto;
    font-size: 1vw;
    font-weight: lighter;
  }

  .qb3-right-section {
    width: 71%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .status-details > img {
    height: 14vw;
  }

  .details {
    font-size: 1vw;
    font-weight: 100;
    margin-top: 0;
    gap: 5.2vw;
  }

  .qb3-image2 {
    width: 20vw;
    margin-bottom: -3vw;
    margin-top: 0;
  }
  .qb3-dropdown-container,
  .dropdown-container2 {
    width: 26vw;
    height: 3vw;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 1.25vw;
    padding-left: 11px;
    font-family: Inter;
  }

  .qb3-textarea {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 53vw;
    height: 11vw;
    margin-top: 4px;
  }

  .qb3-form input {
    height: 3vw;
  }
  .qb3-action-btn {
    width: max-content;
    font-size: 18px;
    padding: 0rem 1rem;
  }

  .selectHoursMinutes input {
    width: 45%;
    height: 3vw;
  }

  #time,
  #hidden-date-input,
  #ticketNum {
    width: 19vw;
  }

  .durationBtnWrapper button {
    height: 2vh;
  }

  .durationBtnWrapper {
    display: flex;
    flex-direction: column;
  }
  .durationFieldWrapper {
    display: flex;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  /* TOP SECTION  */
  .qb2-top-section {
    height: 422px;
    width: 100%;
    background-image: url("../assets/png/Tab-Qb3Img.png");
    object-fit: cover;
    background-color: #3384b1;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px 20px 0px 0px;
    /* background-size: 100% 100%; */
    background-position: center 112%;
  }

  /* .blackfilter {
    background-image: url("../assets/jpg/Overlay.jpg");
  } */

  .qb3.fields-container {
    display: flex;
    flex-direction: row;
    gap: 5vw;
  }

  .qb2-top-section > .qb-heading1 {
    color: #fff;
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 5px 0px 5px 0px;
  }

  #rtDropDown {
    position: absolute;
    right: 2%;
    top: 39%;
  }

  .svgicon {
    padding-top: 5%;
  }

  .qb-desc {
    font-family: "Nunito";
    font-size: 18px;
    color: white;
    width: 37%;
    text-align: center;
    font-weight: 100;
  }

  .tab-container > svg {
    position: absolute;
    right: 2%;
    top: 1%;
  }

  .qb2-top-section > img {
    width: 130px;
    height: 121px;
  }

  .status {
    display: flex;
    gap: 9%;
    width: 92%;
  }

  .status p {
    font-family: "Nunito";
    color: white;
    font-size: 20px;
    width: 52%;
    text-align: center;
  }

  .qb3.textarea {
    width: 621px;
    height: 197px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 10px;
  }

  .qb3.btn-container {
    width: 95%;
    padding-right: 5%;
    justify-content: flex-end;
    gap: 3vw;
  }

  .qb3.form {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }

  .selectHoursMinutes input {
    width: 45%;
    height: 4.2vw;
  }

  .durationBtnWrapper {
    display: flex;
    flex-direction: column;
  }

  .durationFieldWrapper input {
    width: 100%;
    height: 43px;
  }

  .durationBtnWrapper button {
    height: 2.1vh;
  }

  .qb3.btn-container {
    padding-bottom: 5%;
  }

  .css-13cymwt-control {
    width: auto;
    max-width: 90%;
    min-height: 47px !important;
    height: auto;
  }

  .durationBtnWrapper button {
    height: 2.7vh;
  }

  .extra {
    padding-top: 4px;
  }
  .durationBtnWrapper {
    display: flex;
    flex-direction: column;
  }
  .durationFieldWrapper {
    display: flex;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  #seats svg {
    position: absolute;
    top: 5px;
    right: 7px;
  }

  .qb3-label {
    width: 100%;
    font-size: 3vw;
    color: grey;
    font-family: "Nunito";
  }

  .qb3-top-section {
    background: url(../assets/png/Qb3Image.png);
    height: 43%;
    width: auto;
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .label-input-container {
    display: flex;
    justify-content: center;
  }

  .fields-container {
    display: block;
  }

  .selectHoursMinutes input {
    width: 30%;
    height: 6.7vw;
    margin: 5px;
  }

  .selectHoursMinutes {
    justify-content: flex-start;
  }

  .label-input-container {
    margin-left: 5px;
  }

  .durationBtnWrapper {
    width: 93%;
  }

  .facilities-dropdown {
    width: 60%;
  }

  .react-select__control {
    border: solid 1px #817a7a;
  }

  .label-input-container {
    padding-bottom: 15px;
  }

  .extra {
    padding-bottom: 0px;
  }

  .durationFieldWrapper {
    display: flex;
  }
}
