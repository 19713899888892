.formCredInputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}
.formCredWrapper {
  margin-top: 45px;
}
.formCredInputWrapper label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 5px;
}
.formCredInputWrapper input {
  width: 900px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b7b2b2;
}
.buttonCredWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 50px 0 50px;
}

.buttonCredWrapper button:first-child {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background-color: #3384b1;
  border: none;
  width: 125px;
  height: 45px;
  border-radius: 5px;
}
.buttonCredWrapper button:nth-child(2) {
  font-family: Inter;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  background-color: #fff;
  border: 1px solid #000;
  width: 125px;
  height: 45px;
  border-radius: 5px;
}
.iconDiv {
  position: absolute;
  top: 40px;
  right: 20px;
  cursor: pointer;
}

.iconDiv .visible {
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .formCredInputWrapper label {
    font-family: "Nunito";
    font-size: 3vw;
    font-weight: 400;
    color: #808080;
    width: auto;
    margin: 0;
  }

  .formCredWrapper {
    width: 100%;
    height: 38vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .formCredWrapper form {
    display: flex;
    flex-direction: column;
    gap: 4vw;
  }

  .formCredInputWrapper {
    margin: 0;
  }

  .formCredInputWrapper input {
    width: 75vw;
    height: 6vw;
    border-radius: 5px;
    border: 1px solid #b7b2b2;
  }

  .iconDiv {
    position: absolute;
    top: 5vw;
    right: 0vw;
    cursor: pointer;
  }

  .iconDiv.visible img {
    width: 4vw;
    height: 3vw;
  }
  .iconDiv img {
    width: 4vw;
    height: 4vw;
  }

  .buttonCredWrapper {
    margin: 0;
  }

  .buttonCredWrapper button:first-child {
    font-family: Inter;
    font-size: 3.3vw;
    font-weight: 500;
    color: #fff;
    background-color: #3384b1;
    border: none;
    width: 16vw;
    height: 7vw;
    border-radius: 5px;
  }

  .buttonCredWrapper button:nth-child(2) {
    font-family: Inter;
    font-size: 3.3vw;
    font-weight: 500;
    color: black;
    background-color: white;
    border: none;
    width: 16vw;
    height: 7vw;
    border-radius: 5px;
    border: 1px solid black;
  }
}
