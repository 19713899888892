.home-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: linear-gradient(180deg, #e3f5f5 0%, #ffffff 100%);
  overflow: hidden;
}

.home-root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
}

.heading {
  height: 90%;
}

.noData {
  font-size: 1.5rem;
  font-family: Nunito;
  text-align: center;
  width: 100%;
  font-weight: 700;
  color: palevioletred;
}

.home-right-section.home {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2%;
  height: 75vh;
  gap: 2%;
  padding-top: 0.5rem;
}

.home-sidebar.home {
  border: 1px solid;
  width: 6.08vw;
  border-bottom: none;
  border-top: none;
}

.statistics > p,
.room-info > p,
.coach-info.home > p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 700;
}

.stats {
  display: flex;
  width: 100%;
  margin: 0;
  border-radius: 10px;
  /* gap: 4vw; */
  justify-content: space-around;
}

.trmr.room-info {
  margin-left: 37px;
}

.trmr.room-info > p {
  font-weight: 700;
}

.tr.rooms-and-stats {
  display: flex;
  flex-direction: column;
  gap: 32px;
  /* width: 70%; */
  width: 1213.8px;
}

.priority_news_dialog > .css-y5qgh8 {
  height: 70% !important;
}

.priority_news_dialog_content_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px 8px;
}
.priority_news_title {
  font-size: 34px;
  font-family: "Nunito";
  font-weight: 700;
  text-align: center;
}

.priority_news_description {
  font-size: 18px;
  font-family: "Nunito";
  position: relative;
  max-height: 10rem; /* Adjust based on your line-height */
  overflow: auto;
}

/* .priority_news_dialog_news_container { */
/* width: 80%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding: 2rem;
  gap: 2rem; */
/* } */

.priority_news_card {
  box-shadow: 0.75px 0.75px 0.75px 0.75px #00000040;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms all ease-in-out;
  width: 17rem;
}
.priority_news_dialog_news_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow-x: auto;
  padding: 2rem 0rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.priority_news_dialog_content_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
/* .priority_news_dialog_content_container {
  width: 80%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding: 2rem;
  gap: 2rem;
} */

.right-container {
  width: 50px;
  height: 50px;
}
.right-container > img {
  width: 100%;
  height: 100%;
}

.priority_news_card:hover {
  box-shadow: 2px 2px 2px 2px #00000040;
  transition: 200ms all ease-in-out;
}
.trmr.room {
  position: relative;
  height: 287px;
  width: 27%;
  background-color: #ffffff;
}

.priority_news_image_container {
  width: 100%;
  height: 45%;
}

.priority_news_image_container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.priority_news_carousel_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stat {
  padding-top: 13px;
  width: 236px;
  display: flex;
  gap: 11px;
  justify-content: center;
  align-items: center;
  height: 58.8px;
}

#stat-figure {
  width: 65px;
  height: 60px;
  border-radius: 50%;
  background-color: #3384b1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
}

#stat-desc {
  font-size: 20px;
  font-family: Nunito;
  font-weight: 400;
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
}

.stat.final {
  border-right: none;
}

.room-info > .home {
  margin-top: 0;
}

.trmr.room .room-rating {
  position: absolute;
  top: 44%;
  left: 8%;
}

.stat .number {
  color: #3384b1;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  line-height: normal;
  text-transform: capitalize;
}

.stat p {
  margin: 0;
  color: #3384b1;
  text-align: center;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.tr-updates {
  height: 90%;
  /*width: 17vw; */
  width: 24%;
}
/* ROOMS AND UPDATES  */

/* .tr.rooms-and-updates {
  display: flex;
  margin-top: 38px;
  gap: 1vw;
} */

/* .rooms-and-updates.home {
  display: flex;
  margin-top: 38px;
  gap: 14px;
} */

/* .room-cards {
  display: flex;
  gap: 42px;
} */

.room.home {
  width: 350px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
  position: relative;
}

.room-rating.home {
  position: absolute;
  top: 55%;
  left: 5%;
}

.room-img {
  width: 352px;
  height: 174px;
  border-radius: 10px;
}

.room-details.home > span {
  color: #000;
  font-family: Nunito;
  display: flex;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
}

.room-details.home > p {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
  max-height: 2rem;
  overflow: auto;
}

.room.home > p {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 19px;
  margin-bottom: 14px;
}

.room-details.home > svg {
  width: 66px;
}

.details-and-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* coach cards */

/* .coach-card {
  width: 302px;
  height: 274px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
} */

.room-img {
  width: 352px;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
}

.trc.coach-card-container {
  display: flex;
  gap: 50px;
  width: 60vw;
  justify-content: flex-start;
}

/* NEWS AND UPDATES */

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .statistics.home > p {
    color: #000;
    font-family: Nunito;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    margin-bottom: auto;
    line-height: normal;
    text-transform: capitalize;
  }

  .room-details.home > p {
    font-size: 16px;
  }

  .room-info > .home {
    margin-bottom: 4rem !important;
  }
  .stats {
    display: flex;
    width: 89%;
    margin: 0;
    border-radius: 10px;
    justify-content: space-between;
  }

  .stat {
    width: 100%;
    border-right: 1px solid #3384b1;
    display: flex;
    padding: 0;
    gap: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .stat > svg {
    height: 2vw;
  }

  .stat .stat-desc {
    color: #3384b1;
    font-family: Nunito;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1680px) {
  .home-right-section.home {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2%;
    height: 75vh;
    gap: 0;
    padding-top: 0.5rem;
    width: 90%;
  }

  .tr.rooms-and-stats{
    width: 78%;
  }

  .tr-updates {
    width: 25%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .home-container {
    height: 74.5vw;
  }
  .tr.rooms-and-updates {
    display: flex;
    margin-top: auto;
    gap: 1vw;
  }

  .trmr.room-cards {
    display: flex;
  }

  .room-info > .home {
    margin-bottom: 10rem;
  }
  .trmr.room-info > p {
    color: #000;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .home-right-section {
    margin: 0;
  }

  .trmr.room {
    position: relative;
    width: 20vw;
    height: 16vw;
  }

  .trmr.room-info {
    margin-top: 13px;
  }

  .trmr.room > .room-img {
    width: 100%;
    height: 60%;
    object-fit: contain;
  }

  .trmr.room .room-rating {
    position: absolute;
    top: 44%;
    left: 8%;
  }

  .trmr.room .room-details > p {
    margin: 0;
    font-size: 14px;
  }
  .details-and-button {
    height: max-content;
  }

  .trmr.room .room-details > span {
    color: #000;
    font-family: Nunito;
    font-size: 12px;
    display: flex;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    position: relative;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .trmr.room .room-details > span::after {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white; /* Adjust based on background color */
    padding: 0 0.5em; /* Adjust based on your design */
  }

  .trmr.room a > svg {
    width: 2.5vw;
  }

  .star-rating svg {
    width: 1.5vw;
  }

  .trc.coach-card-container {
    /* gap: 3vw; */
    /* width: 65vw; */
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .home-root.tablet {
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100%;
    background: #3384b1;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-container.tablet-home {
    max-height: 92vh;
    overflow-y: auto;
    margin-bottom: auto;
  }

  .statistics.home {
    width: 83vw;
    margin-top: 2vw;
    padding-left: 1vw;
  }

  .statistics.home > p,
  .room-info > p,
  .coach-info > p {
    margin-bottom: 5px;
  }

  .home-right-section.home.tablet {
    margin-left: 0;
  }

  .tr.rooms-and-updates {
    display: flex;
    margin-top: 15px;
    gap: 1vw;
    padding-left: 1vw;
  }

  .trmr.room-info {
    margin-bottom: 0;
    width: 85vw;
  }

  .trmr.room-info > p {
    padding-left: 3vw;
    padding-top: 3vw;
  }

  .tr-updates {
    height: 20vh;
    width: 96%;
    margin-top: 2vw;
    padding-left: 2vw;
  }

  .home-right-section.home {
    flex-direction: column;
    height: 100%;
  }

  .room-info > .home {
    padding-left: 2vw;
  }

  .trmr.room-cards {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    width: 100%;
    overflow-x: hidden;
    height: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    max-width: 100%;
    padding-left: 3vw;
    padding-top: 2vw;
  }

  .room-card-carousel {
    display: flex;
    flex-direction: row;
    gap: 4vw;
  }

  .coach-card-carousel {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  .tabStats {
    display: flex;
    gap: 5%;
  }

  .carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
  }

  .carousel-dot {
    height: 1.2vw;
    width: 1.2vw;
    border-radius: 20px;
    border: 1px solid #3384b1;
  }

  .active-carousel-dot {
    background-color: #3384b1;
  }
  .tabStats-carousel {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  .stats-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .stat-desc-ratings {
    font-family: Nunito;
    font-size: 5.8vw;
    color: #3384b1;
    margin: 0;
    /* opacity: 1; */
  }

  .stat-desc {
    margin: 0;
    font-weight: 200;
    color: #3384b1;
    font-family: Nunito;
    font-size: 2.5vw;
  }

  .trc.coach-card-container {
    width: auto;
  }

  /* */
  .tabStat {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 10px; /* Adjust margin as needed */
  }

  .icon-container.tablet-stats {
    position: absolute;
    top: -15%;
    right: 10px;
    transform: translateY(-50%);
    left: 34%;
    background: rgba(102, 163, 166, 1);
  }

  .icon-container {
    background-color: #619b8a; /* Adjust to match the circle background color */
    border-radius: 50%;
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; /* Adjust margin as needed */
  }

  .stat-desc-ratings {
    color: white; /* Adjust text color as needed */
    font-size: 1.2em; /* Adjust font size as needed */
  }

  .stats-info {
    background-color: white;
    border-radius: 25px; /* Adjust to match the rounded rectangle background */
    padding: 10px 20px; /* Adjust padding as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stat-desc {
    color: black;
    font-size: 1em;
    font-weight: 500;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .stats-rating-container {
    width: 50px;
    height: 50px;
    background-color: rgba(102, 163, 166, 1);
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .priority_news_dialog > .css-y5qgh8 {
    width: 87%;
    height: 60% !important;
    padding: 0.5rem !important;
  }

  .priority-news-carouse-icon {
    width: 30px !important;
    height: unset !important;
  }
  .priority_news_dialog_news_container {
    overflow-y: hidden !important;
  }
  .home-root {
    background: linear-gradient(179.99deg, #e3f5f5 0.01%, #ffffff 151.95%);
    max-height: unset;
    overflow: scroll;
  }
  .stats-title {
    font-size: 16px;
    font-family: "Nunito";
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    width: max-content;
    border-radius: 30px;
    font-weight: bold;
  }
  .user-stats-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 30px;
    box-sizing: border-box;
    font-weight: bold;
  }

  .star-rating svg {
    font-size: 6vw;
  }
  .sectionHeading,
  #coachInfoResp > p {
    font-weight: bold !important;
    font-size: 18px !important;
  }

  .trmr.room.extraClass {
    width: 100vw;
  }

  .user-stats-container-carousel {
    display: flex;
    gap: 2.5rem;
    padding-bottom: 1rem;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
  }
  input {
    width: 94%;
    border-radius: 37px;
    border: 1px solid #000;
    /* aspect-ratio: 10; */
  }

  #mvDescription {
    color: #000;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    display: flex;
    height: 18vw;
    overflow: auto;
  }

  #roomCardResp .room-rating {
    position: absolute;
    top: 45%;
    left: 7%;
  }

  img.mobile-nav-img {
    /* width: 6vw;
    height: 6vw;
    border-radius: 24px; */
  }

  nav svg {
    transform: scale(1.5);
  }
  /* .coach-card.Resp .coach-img {
    display: none;
  } */

  .coach-card.Resp .coach-details {
    margin: 0;
  }
  /* .bellIconWrapper {
    transform: scale(0.8);
  } */

  .mobStats-carousel {
    /* display: flex;
    flex-direction: column;
    gap: 1vw; */
  }
  .mobStats-carousel > .mobStats {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-bottom: 1rem;
    overflow: scroll;
  }

  .mobStat {
    height: 26vw;
    border: 1px solid #3384b1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 15vw;
  }

  .mobStat > svg {
    width: 13vw;
    height: 17vw;
  }

  .mobStat > .stats-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .stats-info p {
    margin: 0;
  }

  .stat-desc-ratings {
    font-size: 36px;
    font-family: "Nunito";
    color: #3384b1;
  }

  .stat-desc {
    margin: 0;
    font-size: 20px;
    font-family: "Nunito";
    color: #3384b1;
  }

  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .carousel-dot {
    height: 2vw;
    width: 2vw;
    border-radius: 20px;
    border: 1px solid #3384b1;
  }

  .active-carousel-dot {
    background-color: #3384b1;
  }

  /* STATS AND HISTORY     */

  .myHistory {
    display: flex;
    flex-direction: column;
    padding: 19px 20px 0px 20px;
    gap: 5px;
  }

  .myHistHeading {
    color: #000;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .numberDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .numberDiv-number {
    color: #3384b1;
    font-family: Nunito;
    font-size: 8.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .numberDiv-name {
    color: #3384b1;
    text-align: center;
    font-family: Nunito;
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  /* TOP RATED MEETING ROOMS  */
  .sectionHeading {
    color: #000;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .meetingRoomSection {
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 15px;
  }

  #roomCardResp {
    height: auto;
    width: auto;
    padding: 11px 10px 9px 11px;
    position: relative;
  }

  IMG.room-img {
    width: 100%;
    height: 37vw;
    border-radius: 10px;
  }

  .room-details.home > p {
    margin: 0;
    color: #000;
    font-family: Nunito;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    width: 86%;
  }

  /* TOP RATED coaches    */

  #coachInfoResp {
    padding: 0px 20px;
  }

  #coachInfoResp > p {
    color: #000;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .coach-card.Resp {
    width: 84vw;
    height: 43vw;
    margin-top: 4em;
    /* margin-bottom: 3em; */
  }

  .carousel-container.coach {
    margin-bottom: 2vw;
  }

  .coach-card p {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .coach-details > p {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1680px) {
  .home-right-section.home {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2%;
    height: 75vh;
    gap: 0;
    padding-top: 0.5rem;
    width: 90%;
  }

  .tr.rooms-and-stats {
    width: 78%;
  }

  .tr-updates {
    width: 25%;
  }
}
