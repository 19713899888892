.room-cards {
  display: flex;
  gap: 1vw;
}

div#roomCardContent {
  width: 81%;
  font-family: "Nunito";
}

.room {
  overflow: hidden;
}

.extraClass {
  transition: 0.7s;
  position: relative; /* Ensure relative positioning for absolute children */
}

.extraClass:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 145px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: 50px;
  border-radius: 35px;
}

.extraClass:hover {
  background: #3384b1;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.extraClass:hover span {
  color: #fff;
}

.hovercolorbubble {
  display: block;
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: 0rem;
  transition: 0.7s;
}

.arrow-container {
  display: inline-block;
  transition: 0.3s; /* Smooth transition for hover effect */
}

.arrow-container svg .circle-bg {
  fill: #3384b1; /* Initial background color */
}

.arrow-container svg .arrow {
  fill: white; /* Initial arrow color */
}

/* Apply hover effect to arrow-container when the parent .extraClass is hovered */
.extraClass:hover .arrow-container svg .circle-bg {
  fill: white; /* Background color on hover */
}

.extraClass:hover .arrow-container svg .arrow {
  fill: #3384b1; /* Arrow color on hover */
}

.room .room-rating {
  position: absolute;
  top: 44%;
  left: 6%;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .details-and-button svg {
    width: 11vw;
  }

  .room-details.home {
    width: 82%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .trmr.room {
    position: relative;
    height: 34vw;
    width: 38vw;
    padding: 1%;
  }

  .room.roomCard-tablet {
    position: relative;
    height: 34vw;
    width: 36vw;
    padding: 1%;
    box-sizing: content-box;
  }

  .room-card.room-img {
    width: 100%;
    border-radius: 20px 20px 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
      0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
    height: 17vw;
  }

  .extraClass:hover {
    background: #fff;
    color: #000;
    transform: scale(1);
    z-index: 9;
  }

  .extraClass:hover .arrow-container svg .circle-bg {
    fill: #3384b1; /* Background color on hover */
  }
  
  .extraClass:hover .arrow-container svg .arrow {
    fill: #fff; /* Arrow color on hover */
  }
  

  .home-right-section {
    margin-left: 0;
  }

  .trmr.room .room-rating {
    position: absolute;
    top: 35%;
    left: 5%;
  }

  .room-rating > .star-rating svg {
    width: 3vw;
    height: 3vw;
  }

  .room-details.home > p {
    font-family: Nunito;
    font-size: 2.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .room-details.home > span {
    color: #000;
    font-family: Nunito;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    height: 11vw;
  }

  a > svg {
    width: 6vw;
    height: 6vw;
  }
  .room .room-rating {
    position: absolute;
    top: 40%;
    left: 6%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .arrow-container {
    width: 35px;
    height: 35px;
  }
  .arrow-container > svg {
    width: 100%;
  }

  .room-details.home > span {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    height: 11vw;
    position: relative;
    max-height: 45px;
    overflow: hidden;
  }
  .room-details.home > span:after {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white; /* Adjust based on background color */
    padding: 0 0.5em;
  }
}
