@media only screen and (min-width: 328px) and (max-width: 768px) {
  .profileFormWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s;
    flex-direction: column;
  }

  span.rating-text {
    display: block;
    font-family: Nunito;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .reviewFormHeaderWrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .resp-review-container {
    display: flex;
    background-color: #3384b1;
    border-radius: 0 0 20px 20px;
    justify-content: center;
    gap: 30px;
    padding: 10px 0;
  }

  #resp-review-container {
    width: 100%;
  }

  .resp-review-header {
    width: 100%;
  }

  .review-dialog-heading {
    background: #3384b1;
    font-size: 4.7vw;
    padding: 12px 0px 12px 0px;
    text-align: center;
    font-family: Nunito;
    text-transform: capitalize;
    color: #fff;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .rating-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .reviewSection {
    background-color: #fff;
    display: flex;
    width: 86%;
    height: auto;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
  }

  span.rating-value {
    font-size: 8vw;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
  }

  label {
    width: 7vw;
  }

  .star-container {
    justify-content: center;
    width: 100%;
    gap: 8px;
  }

  .textarea-container {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .submit-review-btn {
    width: max-content;
    height: max-content;
    font-size: 16px;
    margin: 0;
    padding: 0.65rem 1.25rem;
  }

  .submit-btn-container {
    padding-top: 2vw;
    padding-bottom: 7vw;
    width: 100%;
  }

  input[type="radio"] + svg {
    width: 40px;
    height: 40px;
  }

  .textarea-container > textarea {
    width: 85%;
    font-size: 16px;
    padding: unset;
    padding-top: 1.5rem;
    padding-left: 1rem;
    border-radius: 6px;
  }
}
