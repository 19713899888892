@media only screen and (min-width: 328px) and (max-width: 768px) {
  .coach-img.cust-profilePic {
    width: 27vw;
    height: 27vw;
  }

  .customer-card {
    width: 100%;
    height: 26vh;
    box-sizing: border-box;
  }

  customer-rating {
    position: relative;
    display: flex;
    gap: 82px;
  }

  .customer-details {
    margin: 0;
    margin-top: 0;
  }

  .customer-rating span {
  }

  .rating-and-details {
    display: flex;
    flex-direction: column;

    margin-top: -6vh;
  }

  .customer-details span.cust-name {
    font-size: 5.5vw;
  }

  .cust-name + span {
    font-size: 4vw;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .customer-details {
    width: 100%;
    margin-top: 58px;
    height: 70%;
  }
}
