.select-container {
  display: flex;
  justify-content: end;
  width: 100%;
}

.select-room-form-control {
  width: 30% !important;
  height: 72px;
}
