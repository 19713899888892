.inputContainer {
  margin-top: 10px;
}

.inputContainer.emailregister {
  width: 50%;
}

.inputFieldLabel {
  margin: 5px;
  margin-left: 10;
  margin-bottom: 0px;
  font-size: large;
  font-weight: normal;
  font-family: "Nunito";
}

.inputField {
  width: 100%;
  border-radius: 0px;
}

.nameInputField {
  width: 100%;
}

.inputContainer > .MuiFormControl-root > div > textarea {
  border-radius: 0 !important;
  text-transform: none !important;
  height: 100% !important;
}
.inputContainer > .MuiFormControl-root > div {
  height: 100% !important;
}
.inputField > div > textarea {
  border-radius: 0px !important;
}

.emailregister {
  width: 50%;
}

.MuiInputLabel-root.Mui-focused {
  display: none;
}

@media screen and (max-width: 900px) {
  .inputContainer {
    width: 48%;
  }

  .multilinefield {
    width: 100%;
  }
}
