.survey-card {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .survey-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .survey-card-icon {
    width: 60px;
    height: 60px;
    background-color: #e6f7ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  
  .survey-card-icon svg {
    width: 35px;
    height: 35px;
    color: #1890ff;
  }
  
  .survey-card-content {
    flex: 1;
  }
  
  .survey-card-title {
    font-size: 1.25rem;
    color: #333;
    margin: 0;
  }
  
  .survey-card-date,
  .survey-card-fields {
    font-size: 0.875rem;
    color: #666;
    margin: 5px 0 0;
  }
  
  .survey-card-button {
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.2s ease;
  }
  
  .survey-card-button:hover {
    background-color: #40a9ff;
  }
  