.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 1000;
  background-color: #ffffff;
  height: 30px;
  color: rgba(102, 163, 166, 1);
  font-weight: 500;
}

.footerChildDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 20px;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .footerChildDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 11px;
    height: 100%;
    font-weight: bolder;
  }

  footer {
    position: fixed;
    width: 13%;
    bottom: 0px;
    z-index: 1000;
    background-color: transparent;
    height: 30px;
    color: rgba(102, 163, 166, 1);
    font-weight: 500;
    right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .footerChildDiv {
    font-size: 13px;
  }

  .desktop-content {
    background: linear-gradient(179.99deg, #e3f5f5 0.01%, #ffffff 151.95%);
  }

  .mobile-blocked {
    display: block;
    text-align: center;
    padding: 50px;
    font-size: 24px;
  }
}

@media only screen and (min-width: 769px) {
  /* .mobile-blocked {
    display: none;
  } */
}
