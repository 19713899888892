.stats-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 20%;
  height: 57.21px;
  background: white;
  border-radius: 28.605px; /* Half of the height to make the edges round */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 5px; */
  position: relative;
}

.stats-container > .text {
  font-weight: 700;
  padding: 0rem 1rem;
}

.circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 59px;
  padding: 1rem;
  background-color: #3384b1;
  border-radius: 50%;
  color: white;
  font-size: 25px;
  font-family: "Nunito";
  font-weight: bold;

}

.text {
  font-size: 20px;
  font-weight: 200;
  font-family: "Nunito";
  width: 80%;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .stats-container {
    width: 22%;
    height: 43px;
  }

  .statistics.home {
    padding-left: 36px;
  }

  .circle {
    font-size: 17px;
    width: 23%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .text {
    font-size: 18px;
    width: max-content;
  }
  .stats-container > .text {
    font-size: 15px;
    line-height: 18px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 720px) {
  .circle {
    width: 24%;
  }
}
