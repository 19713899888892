.profileFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
  font-family: "Nunito";
}


/* Main box */
.avContainer {
  width: 584px;
  height: auto;
  position: absolute;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
}

.updateUserHeading {
  font-family: "Nunito";
  text-align: center;
  background: #3384b1;
  margin: 0;
  height: 53px;
  color: white;
  border-radius: 17px 17px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.updateUserHeading + svg {
  position: absolute;
  right: 3%;
  top: 2%;
}

/* Header styles */
.header {
  /* position: absolute; */
  left: 46px;
  top: 41px;
  text-align: center;
  color: black;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 17.38px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.dateTimeInputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}


input[type="datetime-local"] {
    all: unset;
    appearance: auto;
  }

.subHeader {
  position: absolute;
  left: 46px;
  top: 74px;
  text-align: center;
  color: #757575;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 14.22px;
}

/* Status input styles */
.statusInput {
  width: 488px;
  height: 250px;
  position: absolute;
  left: 48px;
  top: 109px;
  background: #ebebeb;
  border-radius: 10px;
}

.statusHint {
  position: absolute;
  left: 57px;
  top: 129px;
  text-align: center;
  color: #757575;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 11.06px;
}

/* Toggle section styles */
.toggleBox {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 48px;
  top: 373px;
  border-radius: 2px;
  border: 0.5px solid #757575;
}

.toggleText {
  position: absolute;
  left: 70px;
  top: 376px;
  text-align: center;
  color: #757575;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 12.64px;
}

/* Date and time input styles */
.inputBox {
  width: 240px;
  height: 46px;
  position: absolute;
  background: #ebebeb;
  border-radius: 10px;
}

.fromInput {
  left: 46px;
  top: 442px;
}

.toInput {
  left: 296px;
  top: 442px;
}

.label {
  position: absolute;
  color: #757575;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 11.06px;
}

.fromLabel {
  left: 54px;
  top: 459px;
}

.toLabel {
  left: 304px;
  top: 459px;
}

/* Update button styles */
.updateButton {
  width: 101px;
  height: 33px;
  position: absolute;
  left: 242px;
  top: 527px;
  border-radius: 4px;
  border: 1px solid #3384b1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateText {
  color: #3384b1;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
