.days-container {
  display: flex;
  flex-direction: column;
  gap: 61px;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 56px;
}

.day-wise-blocks-container {
  display: flex;
  gap: 3px;
}

.overall-time-blocks {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 28px;
}

.days-blocks {
  display: flex;
  gap: 21px;
}

.time-block {
  width: 45px;
  height: 84px;
  background-color: #ededed;
}

.time-slot-blocks {
  display: flex;
  gap: 53px;
  margin-top: 1vw;
  margin-left: 139px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .time-block {
    width: 6vw;
    height: 58px;
  }

  .time-slot-blocks {
    display: flex;
    gap: 27px;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }
}
