.profileFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
  font-family: "Nunito";
}

.profileFormHeaderWrapper {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: center;
}
.profileFormHeader {
  display: flex;
  background-color: #3384b1;
  border-radius: 0 0 20px 20px;
  width: 30rem;
  justify-content: center;
  gap: 30px;
  padding: 10px 0;
}
.profileFormHeaderWrapper span {
  position: absolute;
  top: 2%;
  right: 4%;
  cursor: pointer;
}

.formSection {
  background-color: #fff;
  display: flex;
  width: 1352px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  position: relative;
}

/* transition css */

.profileTag {
  background-color: #3384b1;
  color: #fff;
  cursor: pointer;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.profileTag.active {
  background-color: #fff;
  color: #3384b1;
  width: auto;
  height: 45px;
  margin: 5px 0 0px;
  border-radius: 10px;
  padding: 0px 10px;
}
.profileTag h2 {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 300;
}
.profileTag svg path {
  fill: #fff;
}
.profileTag.active svg path {
  fill: #3384b1;
}

#closeManageAccount {
  height: 31px;
  width: 31px;
}
/*  */

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .profileFormHeader {
    padding: 0;
  }

  .profileFormHeaderWrapper {
    width: 45%;
  }

  .profileTag h2 {
    font-family: Nunito;
    font-size: 20px;
    font-weight: 300;
    color: white;
  }

  .profileTag.active h2 {
    margin: 0;
    color: #3384b1;
    padding: 0.5rem;
  }
  .reviewFormHeader > .profileTag.active.review > h2 {
    color: #ffffff !important;
  }
  .reviewFormHeader > .profileTag > h2 {
    color: #3384b1 !important;
  }

  .profileTag.active {
    background-color: #fff;
    color: #3384b1;
    width: 16%;
    height: 78%;
    margin: 5px 0 0px;
    border-radius: 10px;
    padding: 0 3%;
  }

  .profileTag svg {
    width: 35px;
  }

  .profileTag {
    gap: 8%;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .formSection {
    width: 92vw;
    height: auto;
  }

  form {
    width: 73vw;
  }

  .formWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .formInputWrapper input {
    width: 74vw;
    height: 7vw;
  }

  .profileFormHeaderWrapper span {
    position: absolute;
    top: 7%;
    right: 4%;
    cursor: pointer;
  }

  #closeManageAccount {
    width: 25px;
  }

  .profileFormHeaderWrapper {
    width: 94vw;
    height: unset;
    padding-left: 2vw;
    padding-right: 1vw;
    border-radius: 0 0 34vw 4vw;
  }

  .mv.profileFormHeader {
    display: flex;
    background-color: #3384b1;
    border-radius: 0 0 20px 20px;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 73%;
    padding: 2vw;
    height: max-content;
    box-sizing: border-box;
  }

  .profileTag {
    font-size: 7vw;
    display: flex;
    gap: 0;
    flex-direction: column;
    width: 17vw;
    height: 8vw;
    padding: 1%;
  }

  .profileTag.active {
    width: max-content;
    height: max-content;
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  label {
    width: 16vw;
  }

  .profileTag.active {
    margin: 0;
  }

  .profileTag h2 {
    font-size: 13px;
    margin: 0;
  }

  .locationInputWrapper input {
    height: auto;
  }
}
