@media only screen and (min-width: 328px) and (max-width: 932px) {
  .edit-dialog.review-header {
    height: 11vw;
    width: 100%;
    margin-right: 0px;
  }

  .login-dialog-heading {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size:20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 79%;
    text-transform: capitalize;
}

.MuiBox-root .review-container{
  height: 100%;
  overflow: scroll;
}

/* .review-container{
  margin-top: 60px;
} */

.review-header{
  margin-top: 10%;
  margin-left: 32%;
  height: 100px;

}

.css-1j0rj7u{
  height: 50% !important;
}



}
