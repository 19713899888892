@media only screen and (min-width: 769px) and (max-width: 1024px) {
  div.review-header {
    height: 8vw;
    margin-left: 0;
  }

  .login-dialog-heading {
    font-size: 3.86vw;
  }

  span.rating-text {
    font-size: 2.83vw;
  }

  span.rating-value {
    font-size: 9.63vw;
  }

  .star-container {
    gap: 0px;
  }

  .star-container label > svg {
    width: 5.49vw;
    height: 5.49vw;
  }

  .textarea-container {
    margin-top: 1em;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  textarea {
    width: 55.1vw;
    height: 29vw;
    font-size: 2vw;
    padding-left: 5%;
    padding-top: 3%;
  }

  .submit-review-btn {
    width: 19.2vw;
    height: 4.79vw;
    font-size: 2.7vw;
  }

  label {
    width: auto;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .div.review-header {
    height: 55px;
  }
}

.meeting-room-review-dialog > .css-eql3zb {
}
