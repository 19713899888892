.mr-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  background: linear-gradient(180deg, #e3f5f5 0%, #ffffff 100%);
}

.mr.mr-root {
  max-height: 100vh;
  display: flex;
  overflow: hidden;
}

.mr-container {
  flex: 1;
}

.mr-root {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

/* FILTERS  */

.mr.filter-container {
  width: 87vw;
  height: auto;
  margin-top: 23px;
  display: flex;
  align-items: start;
  gap: 3%;
}

.mr-filter-top,
.mr-filter-bottom {
  display: flex;
}

.mr-filter-bottom {
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.mr-filter1 {
  width: 362px;
  height: 63px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  padding: 0px 14px;
  position: relative;
}

/* FILTER BOTTOM  */

.mr-filter-icon {
  width: 60px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid;
}

.mr-filter-facility.mr-dropdown {
  width: auto;
  border-right: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
}

.mr-filter-facility.mr-dropdown input {
  height: auto;
  width: auto;
  border: none;
  font-size: 1vw;
  font-family: "Nunito";
  font-weight: 100;
}

.newsAdmin {
  overflow: hidden;
}

.mr-filter-btn {
  width: 437px;
  height: 119px;
  background-color: #3384b1;
  color: #fff;
  font-family: Nunito;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mr-right-section {
  padding-left: 2vw;
  overflow-y: auto;
  max-height: 90vh;
  overflow-x: hidden;
}

.mr-sidebar {
  border: 1px solid;
  width: 118px;
  border-bottom: none;
  border-top: none;
}

.trmr.room-info.mr {
  margin-top: 0px;
  margin-bottom: 0px;
}

.room-info > p {
  margin-top: 0;
}

.statistics > p,
.room-info > p,
.mr-available > p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

/* ROOMS AND UPDATES  */

.mr-allroms {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.room-cards {
  display: flex;
  gap: 42px;
}

.room {
  width: 350px;
  height: 283px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
}

.room-img {
  width: 352px;
  height: 174px;
  border-radius: 10px;
}

.room-details > span {
  color: #000;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.room-details > p {
  margin: 0;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.room > p {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 19px;
  margin-bottom: 14px;
}

.room-details > svg {
  width: 66px;
}

.details-and-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* coach cards */

.room-img {
  width: 352px;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
}
.custom-calendar-icon {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; /* Allows clicking through the SVG to the input */
  /* Additional styling for your SVG icon */
}
/* AVAILABLE MEETING ROOMS  */

.mr-available {
  margin-top: 1em;
  margin-bottom: 3vw;
}

.mr-available-container {
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
}

/* NEWS AND UPDATES */

#filterByDate {
  width: 87%;
  height: 98%;
  font-size: 13px;
  border: none;
  position: absolute;
  z-index: auto;
  font-family: "Nunito";
  font-weight: 600;
  padding-left: 20px;
}

#filterByTime {
  border: none;
  width: 100%;
  height: 98%;
  z-index: auto;
  font-size: 13px;
  font-family: "Nunito";
  font-weight: 600;
  padding-left: 20px;
}

.custom-calendar-icon {
  position: absolute;
  top: 7px;
  pointer-events: none;
  left: 9px;
}

#filterByDate::-webkit-calendar-picker-indicator {
  opacity: 0;
  z-index: 9;
  position: absolute;
  left: 80%;
  font-size: 50px;
  width: 50px;
}

#filterByDate,
#filterByTime {
  outline: none;
}

input#filterByTime::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 67%;
  opacity: 0;
  z-index: 9;
  width: 50%;
  height: 100%;
}

#filterByDuration {
  height: 93%;
  width: 100%;
  border: none;
  font-size: 13px;
  font-family: Nunito;
  font-weight: 600;
}

.dropdown-options.filter {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 349px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  top: 40px;
  left: -1px;
  list-style: none;
  margin-top: 15px;
  display: block;
}

#filterByDuration:focus,
#filterByCapacity:focus {
  outline: none;
}

#filterByCapacity {
  width: 64%;
  height: 90%;
  border: none;
  font-size: 22px;
}

.filterField {
  position: relative;
  width: 200px;
  height: 48px;
}

.dropdown-options.cap-filter {
  top: 98%;
  left: 22%;
  width: 78%;
  list-style: none;
  position: absolute;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

/* PRICE FILTER  */

#filterByPrice {
  font-size: 13px;
  width: 61%;
  border: none;
}

#filterByfacility {
  font-size: 13px;
  width: auto;
}

.mr-filter-credits.mr-dropdown {
  width: 13vw;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
  padding-left: 20px;
}

.ip-svg-section {
  position: relative;
}

.price-filter-svg {
  /* position: absolute;
  top: 33%;
  width: 3vw; */
}

.dropdown-options.price-filter {
  top: 55px;
  width: 205px;
  list-style: none;
}

span.filter-name {
  font-family: "Nunito";
  font-weight: 200;
  opacity: 0.5;
  font-size: 22px;
}

/* RATINGS FILTER  */

.mr-filter-ratings.mr-dropdown {
  width: 256px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding-left: 20px;
  border-right: 1px solid black;
}

.ratings-filter-svg {
  /* position: absolute;
  top: 18px;
  left: 14px; */
}

/* AVAIL FILTER  */
.mr-filter-avail.mr-dropdown {
  width: 296px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding-left: 20px;
  border-right: 1px solid black;
}

.avail-filter-svg {
  position: absolute;
  top: 12px;
  left: 53px;
}

.dropdown-options {
  list-style: none;
  font-family: "Nunito";
  font-weight: 200;
}

.dropdown-options.avail-filter {
  top: 55px;
  width: 308px;
  left: 0px;
}

.dropdown-options.price-filter {
  font-size: 20px;
  background-color: white;
  z-index: 99999;
  position: absolute;
  right: -10%;
  border: black;
}

/* PAGINATION STYLES */
.paginationNumbers {
  display: flex;
  gap: 0.5rem;
  width: 90%;
}

.paginationNumbers button {
  border: 1px solid #3384b1; /* Match your theme color */
  background-color: transparent; /* Default background color */
  color: #3384b1; /* Text color */
  cursor: pointer; /* Pointer cursor for better UX */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.paginationNumbers button:hover {
  background-color: #e0f7f9; /* Lighter shade on hover */
  color: #333; /* Text color on hover */
}

.paginationNumbers button.activePage {
  background-color: #3384b1; /* Highlighted background color for active page */
  color: white; /* Text color for active page */
  border: 1px solid #3384b1; /* Border color for active page */
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .mr-mv-root {
    display: flex;
    flex-direction: column;
    gap: 4vw;
    max-height: 100vh;
    background: linear-gradient(179.99deg, #e3f5f5 0.01%, #ffffff 151.95%);
  }

  .nav-filterSection {
    z-index: 1;
    width: 100%;
  }

  .mr-filter-container.mr-Resp {
    width: 90%;
    height: 69px;
    padding: 5vw 5vw 0px;
    margin: 0;
  }

  .mr-filter-mobileDiv {
    border: 1px solid rgb(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: row;
    gap: 0;
    width: 100%;
  }

  .meetingRoomSection.mr {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-left: 0;
    width: 100vw;
    align-items: center;
    margin-bottom: 2vh;
  }

  .room-details.mv-mr p {
    font-size: 5vw;
  }
  .room-details.mv-mr span {
    font-size: 4vw;
  }

  .filter.filterSection {
    width: 85.97%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mr-filter-top.mr-Resp,
  .mr-filter-bottom.mr-Resp {
    width: 100%;
    height: 50%;
    border-right: 1px solid rgb(0, 0, 0, 0.1);
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    border-left: none;
  }

  .mr-filter1.mr-Resp {
    width: 46.82%;
    height: 100%;
    border-right: 1px solid rgb(0, 0, 0, 0.1);
    border-left: none;
    border-top: none;
    border-bottom: none;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }

  .mr-filter1.mr-Resp.time {
    padding: 0;
    width: 32.82%;
  }

  .dropdown-options.price-filter {
    top: 105%;
    width: 100%;
    list-style: none;
    font-size: 2.5vw;
  }

  .dropdown-options.avail-filter {
    top: 108%;
    width: 100%;
    left: 0px;
    font-size: 2.5vw;
  }

  .price-filter-svg {
    position: absolute;
    top: 40%;
    width: 6vw;
  }

  .avail-filter-svg {
    position: absolute;
    top: 39%;
    right: 4%;
  }
  .mr-filter1.final {
    width: 42.36%;
    height: 100%;
    flex-direction: row;
    gap: 10px;
  }

  .searchBarDiv {
    background: #3384b1;
    width: 14.03%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #filterByfacility {
    font-size: 2.5vw;
  }

  #filterByDate.mv {
    width: 77%;
    height: 100%;
    margin: 0;
    font-size: 2.5vw;
    position: absolute;
    background-color: transparent;
    /* left: 20px; */
  }

  #filterCalendarSvg {
    position: absolute;
    left: 6px;
  }
  #filterClockSvg {
    position: absolute;
    left: 2px;
  }

  input#filterByDate::-webkit-calendar-picker-indicator {
    position: absolute;
    left: -12px;
    transform: scale(28.5);
  }

  input#filterByTime::-webkit-calendar-picker-indicator {
    opacity: 0;
    transform: scale(1.5);
    position: absolute;
    left: -5px;
  }

  input#filterByDuration {
    font-size: 2.5vw;
    aspect-ratio: auto;
  }

  input#filterByTime {
    font-size: 2.5vw;
    height: 80%;
    width: 73%;
    background-color: transparent;
  }

  .sliderIcon.container {
    width: 10.58%;
    border-right: 1px solid rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #filterByContainer {
    font-size: 2.5vw;
  }

  .dropdown-options.filter {
    width: 100%;
    top: 58%;
    font-size: 2.5vw;
  }

  .dropdown-options.cap-filter {
    top: 103%;
    width: 99%;
    list-style: none;
    font-size: 2.5vw;
  }

  .mr-filter-facility.mr-dropdown {
    width: 27vw;
    gap: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 2.5vw;
  }

  .mr-filter-facility.mr-dropdown input {
    font-size: 2.5vw;
  }

  #filterByCapacity {
    font-size: 2.5vw;
    aspect-ratio: 0;
    font-size: 2.5vw;
  }

  #filterByCapacity::placeholder {
    font-size: 2.5vw;
  }

  #capcityFilter {
    position: absolute;
    top: 40%;
    right: 10%;
  }

  .filter-name {
    font-size: 2.5vw;
  }

  #filterByPrice {
    font-size: 2.5vw;
    width: 92%;
  }

  .mr-filter-credits.mr-dropdown,
  .mr-filter-avail.mr-dropdown,
  .mr-filter-ratings.mr-dropdown {
    width: 20vw;
    padding: 0;
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
  }

  .ratings-filter-svg {
    position: absolute;
    top: 38%;
    right: 9%;
  }

  .mr-filter-facility.mr-dropdown input {
    width: 100%;
  }

  .mr-filter-facility.mr-dropdown {
    width: fit-content;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .meeetingrooms-root.tablet {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  .mr-filter-facility.mr-dropdown input {
    height: 90%;
    width: 96%;
    border: none;
    font-size: 2vw;
    font-family: "Nunito";
    font-weight: 100;
  }

  .mr-filter-div.tablet {
    display: flex;
    flex-direction: column;
    width: 82%;
    gap: 0;
  }

  .nav-home-rightsection {
    max-height: 100%;
    display: flex;
    flex-direction: row;
  }

  #tabletRoomContainer {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .home-container {
    flex: 1;
    overflow: hidden;
  }

  .mr-right-section.home {
    display: flex;
    flex-direction: column;
  }

  .mr.filter-container {
    width: 78vw;
    height: 7vw;
    margin-top: 23px;
    display: flex;
    gap: 0;
  }

  .mr-allroms {
    overflow: hidden;
  }

  .mr-available {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .mr-available-container {
    overflow: auto;
  }

  .mr-filter1.meetingrooms {
    width: -webkit-fill-available;
    height: 3.5vw;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    padding: 0px;
  }

  #filterByDate {
    width: 84%;
    margin-left: 0;
    height: 76%;
    font-size: 16px;
    border: none;
    position: absolute;
    left: 0px;
    z-index: auto;
    font-family: Nunito;
    font-weight: 100;
  }

  #filterByTime {
    border: none;
    width: 100%;
    height: 91%;
    z-index: auto;
    font-size: 16px;
  }

  #filterByTime + svg {
    width: 2vw;
    height: 2vw;
  }

  #filterByDuration {
    height: 82%;
    width: 100%;
    border: none;
    font-size: 16px;
    font-family: Nunito;
    font-weight: 100;
  }

  #filterByPrice {
    font-size: 2vw;
    width: 73%;
    border: none;
    height: 56%;
    font-family: "Nunito";
    font-weight: 100;
  }

  .mr-filter1 {
    width: 142px;
    height: 32px;
    padding: 0px;
  }

  .mr-filter-bottom {
    border-bottom: 1px solid;
    border-left: 1px solid;
    height: 25px;
  }

  .mr-filter-icon.meetingrooms {
    width: 3vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
  }

  .mr-filter-icon > svg {
    width: 1.5vw;
    height: 1.5vw;
  }

  .mr-filter-btn {
    width: 180px;
    height: 54px;
    background-color: #3384b1;
    color: #fff;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-calendar-icon {
    position: absolute;
    top: 9%;
    pointer-events: none;
    left: 85%;
    width: 2vw;
  }

  .mr-filter-ratings.mr-dropdown {
    width: 11vw;
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding-left: 12px;
    border-right: 1px solid black;
  }

  #filterByfacility {
    font-size: 13px;
    width: 100%;
  }

  .ratings-filter-svg {
    position: absolute;
    top: 37%;
    left: 73%;
  }

  .mr-filter-facility.mr-dropdown {
    width: 25vw;
    height: auto;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
  }

  #filterByCapacity {
    width: 64%;
    height: 90%;
    border: none;
    font-size: 2vw;
    font-family: "Nunito";
    font-weight: 100;
  }

  /* FILTER SECTION ENDS  */
  .mr-allroms {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
  }

  .room-cards {
    display: flex;
    gap: 42px;
    max-width: 80vw;
    overflow-x: hidden;
    height: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }

  .room-cards.available {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap between cards */
    overflow-x: hidden;
    height: auto;
  }

  .dropdown-options.price-filter {
    font-size: 20px;
    background-color: white;
    z-index: 99999;
    position: absolute;
    right: 12%;
    top: 90%;
    border: black;
    width: 100px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .mr-filter-div {
    width: 65%;
  }

  .mr-filter-top,
  .mr-filter-bottom {
    height: 3.5vw;
  }

  .mr-allroms {
    margin-top: 0px;
  }
  .mr-available-container {
    margin-bottom: 3rem;
  }

  .mr-filter1 {
    width: 30%;
    height: 100%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
  }

  .filterField {
    width: 21%;
  }
  #filterByDate {
    width: 74%;
    margin-right: 17px;
    margin-left: 0;
    height: 98%;
    font-size: 16px;
    border: none;
    position: absolute;
    z-index: auto;
    font-family: Nunito;
    font-weight: 100;
  }

  #filterByDuration {
    padding-left: 16px;
  }
  .custom-calendar-icon {
    position: absolute;
    /* top: 19%; */
    pointer-events: none;
    /* left: 84%; */
    width: 1.5vw;
  }

  .mr-filter-btn {
    width: 25vw;
    height: 7vw;
    background-color: #3384b1;
    color: #fff;
    font-family: Nunito;
    font-size: 2vw;
    font-style: normal;
  }

  #filterByTime {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
  }

  #filterByDuration {
    height: 93%;
    width: 100%;
    border: none;
    font-size: 16px;
    font-family: Nunito;
    font-weight: 100;
  }

  .mr-filter-icon {
    width: 4vw;
    height: 100%;
  }

  /* FILTER SECTION DONE  */

  .room-cards > .trmr.room {
    position: relative;
    width: 30%;
    height: 243px;
    overflow: hidden !important;
    box-sizing: border-box;
  }

  span.filter-name {
    font-family: "Nunito";
    font-weight: 200;
    opacity: 0.5;
    font-size: 1.5vw;
  }

  .mr-filter-credits.mr-dropdown {
    width: 15vw;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0em;
    padding-left: 12px;
    border-right: 1px solid black;
  }

  #filterByPrice {
    font-size: 16px;
    padding-left: 11%;
    width: 70%;
    border: none;
  }

  .price-filter-svg {
    position: absolute;
    /* top: 50%; */
    width: 1vw;
  }

  .mr-filter-ratings.mr-dropdown {
    width: 13vw;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    border-right: 1px solid black;
  }

  #filterByfacility {
    font-size: 16px !important;
    width: 21% !important;
  }
  .topFilterContainer {
    gap: 24px !important;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-around; */
  }
  .input-with-icon.filterInputDiv {
    width: 100% !important;
    box-sizing: border-box;
  }
  .mr-filter-facility.mr-dropdown {
    width: auto;
    border-right: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
  }

  .mr-filter-facility.mr-dropdown > svg {
    position: absolute;
    top: 44%;
    right: 4%;
  }
}

/* new styles  */
.input-with-icon {
  display: flex;
  align-items: center;
  border-radius: 35px;
  background-color: white;
  width: 101%;
  height: 47px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.input-with-icon.filterInputDiv {
  display: flex;
  align-items: center;
  position: relative;
  width: 111%;
  padding-right: 1vw;
}

.filterInputDiv input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.input-label {
  flex-grow: 1;
  font-size: 24px;
  color: black;
}
mr-filter-div > input[type="date"],
mr-filter-div > input[type="time"],
mr-filter-div > input[type="text"] {
  flex-grow: 2;
  border: none;
  background: transparent;
  font-size: 24px;
  color: black;
  outline: none;
}

.mr-filter-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 82%;
}

.topFilterContainer {
  display: flex;
  gap: 4%;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3384b1; /* Adjust color as needed */
  border-radius: 53%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: absolute;
}

.icon-container.filterInputDiv {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.icon-container img {
  width: 24px;
  height: 24px;
}

.searchIcon {
  background: #3384b1;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  padding: 0.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIcon.history {
  width: 123px;
}

.action-icon-container {
  display: flex;
  gap: 1rem;
}

.bottomFilterContainer {
  display: flex;
  gap: 11%;
}

.icon-container img,
.icon-container svg {
  width: 17px;
  height: 17px;
}

.input-with-icon input {
  border: none;
  background: transparent;
  flex-grow: 1;
  font-size: 24px;
  color: black;
  padding-left: 20px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1;
  max-height: 150px; /* Adjust as needed */
  overflow-y: auto; /* Add scrollbar if needed */
}

.dropdown-options span {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-options span:hover {
  background-color: #f0f0f0;
}

.input-with-icon > input {
  font-family: Nunito;
  font-weight: 600;
  font-size: 13px;

  outline: none;
}

.filterField.filterByfacility {
  width: 400px;
}
