.basicDeatilsForm {
  width: 90%;
  /* display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
    row-gap: 20px; */
}

.fullName {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}
.fullName > .inputContainer {
  width: 47% !important;
}

.fullName > .inputComtainer > .inputField {
  width: 125%;
}

.MuiInputLabel-root.Mui-focused {
  display: none;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .fullName {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }

  .fullName > .inputContainer {
    width: 90% !important;
  }
  .tablet-setupContent {
    max-height: 50%;
    overflow: auto;
  }
}

/* .basicDeatilsForm{
    margin-top:5%;
} */
