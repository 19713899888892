.shimmer-card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 22%;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
  background: #f0f0f0;
  height: 300px;
}

.home-shimmer-card {
  height: 250px;
  width: 30%;
}

.shimmer {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 38%, #f0f0f0 54%);
  background-size: 1000px 100%;
}

.shimmer-img {
  width: 100%;
  height: 160px;
  border-radius: 20px 20px 0 0;
  background-color: #e0e0e0;
}

.shimmer-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shimmer-title {
  width: 70%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.shimmer-description {
  width: 100%;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

/* Responsive design to match RoomCard styles */
@media only screen and (min-width: 328px) and (max-width: 768px) {
  .shimmer-card {
    height: 200px;
  }

  .shimmer-img {
    height: 100px;
  }

  .shimmer-title,
  .shimmer-description {
    height: 15px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .shimmer-card {
    height: 250px;
  }

  .shimmer-img {
    height: 140px;
  }

  .shimmer-title {
    width: 60%;
    height: 18px;
  }

  .shimmer-description {
    height: 14px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .shimmer-card {
    height: 280px;
  }

  .shimmer-img {
    height: 150px;
  }

  .shimmer-title {
    width: 65%;
    height: 16px;
  }

  .shimmer-description {
    height: 12px;
  }
}
