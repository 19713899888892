.rev-root-container {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  margin-top: 2%;
  margin-bottom: 4%;
  overflow-y: scroll;
  max-height: 700px;
  align-items: center;
  width: 75%;
  padding-right: 1rem;
}

.mv.reviewFormHeader {
  display: flex;
  /* background-color: #3384b1; */
  border-radius: 0 0 20px 20px;
  justify-content: center;
  gap: 30px;
  align-items: center;
  width: 100%;
  /* padding: 2vw; */
  /* height: 0%; */
  padding: 1rem 0rem;
}

.reviewFormHeader > .profileTag.active {
  background-color: #3384b1;
  color: #ffffff;
}
.reviewFormHeader > .profileTag {
  background-color: #ffffff;
  color: #3384b1;
  border: 1px solid #3384b1;
  width: auto;
  height: 2vw;
  margin: 5px 0 0px;
  border-radius: 10px;
  padding: 0px 10px;
}
.rc-review-container {
  /* margin-top: 21px; */
  width: 75%;
}

.nullReviewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

#nullReviewImg {
  height: 50%;
}

.nullReviewMsg {
  font-family: "Nunito";
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .rev-root-container {
    display: flex;
    flex-direction: column;
    gap: 6vw;
    overflow-y: scroll;
    padding-top: 10%;
    padding-left: 3%;
    margin: 0;
    width: 100%;
  }
  .reviewFormHeader > .profileTag {
    width: max-content;
    height: max-content;
  }

  #nullReviewImg {
    height: auto !important;
    width: 63%;
  }
  .mv.reviewFormHeader {
    height: 59%;
  }
  .nullReviewMsg {
    font-weight: 600;
  }

  .edit > svg {
    width: 4vw;
    height: 5vw;
  }

  .profileTag.review {
    height: 58%;
    padding: 2%;
  }

  .reviewFormHeader > div {
    width: 220px !important;
  }
  .reviewFormHeader > .profileTag h2 {
    width: max-content;
    padding: 0.5rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .mv.reviewFormHeader {
    height: 40%;
  }


  .reviewFormHeader > .profileTag {
    background-color: #ffffff;
    color: #3384b1;
    border: 1px solid #3384b1;
    width: auto;
    height: 4vw;
    margin: 5px 0 0px;
    border-radius: 7px;
    padding: 0px 10px;
  }

  .rev-root-container {
    width: 99%;
  }

  .reviewcontainer {
    border: 1px rgba(102, 163, 166, 1) solid;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .profileTag.active.review {
    color: #3384b1;
    width: max-content;
    height: max-content;
    border-radius: 10px;
  }

  .profileTag.review {
    width: max-content;
    margin: unset;
    height: 3.5vw;
  }

  /* .rev-root-container {
    margin-top: 0;
  } */

  .rev.review-container {
    padding: 6px 14px 6px 14px;
    border: 1px solid #3384b1;
    width: 65vw;
    height: 12vw;
    border-radius: 10px;
  }
}
