.bellIconWrapper {
  position: relative;
  cursor: pointer;
}
.notificationNumberDiv {
  width: 19px;
  height: 19px;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 26px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notificationNumberDiv span {
  /* padding: 0 5px 0; */
  color: #fff;
  font-size: 15px;
}
/* notifications panel */
.notificationTextWrapper {
  position: absolute;
  top: 130px;
  right: 15%;
  width: 38.813rem;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s, max-height 0.5s;
}
.notificationTextWrapper.show {
  opacity: 1;
  max-height: 500px;
  z-index: 111;
  /* overflow: auto; */
}
/* check this css for rounded profile image */
.notificationProfileImg {
  border-radius: 50%;
  height: 4.375rem;
  width: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
/* check this css for rounded profile image */
.notificationTextWrapper h3 {
  font-family: Nunito;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #3384b1;
  margin: 0 0;
  padding: 5px 0;
}

.reqClass {
  width: 3rem;
  /* height: 44px; */
}

.notificationClass {
  display: flex;
  gap: 20px;
}
.notificationClass {
  display: flex;
  gap: 20px;
  padding: 12px 20px;
  border-bottom: 1px solid #b3b3b3;
}

.notif-container {
  background: rgba(255, 255, 255, 0.2);
  /* padding: 10% 16%; */
  border-radius: 9px;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notificationDetails h2 {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0;
}
.notificationTxtClass {
  display: flex;
  align-items: center;
  gap: 25px;
}

.notificationDetails p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 5px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 21vw;
  position: relative;
}
/* added */
.messageDiv p:nth-child(2) {
  width: 2rem;
  padding: 0 30px 0 0;
}
.messageDiv {
  display: flex;
  align-items: flex-start;
}
.messageDiv span {
  color: black !important;
}
.svgDrawer {
  cursor: pointer;
}
/*  */
.notificationTxt p::after {
  content: "..."; /* Ellipsis indicator */
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff; /* Match background color */
  padding: 0 4px; /* Add padding for better appearance */
}

.notificationDetails span {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  color: #b3b3b3;
  padding-right: 15px;
}
.notificationTimeClass {
  display: flex;
  gap: 10px;
}
.notifWatch {
  display: flex;
}
.notificationTimeClass p {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  color: #b3b3b3;
}

.notificationTxt {
  display: flex;
  gap: 10px;
}

.notifi-container {
  overflow: auto;
  max-height: 566px;
}

.notification-img {
  width: 100%;
  height: 100%;
}
/* css for drawer */
.drawer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  /* border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  border-radius: 4px;
  animation: slideIn 0.5s forwards; /* Animation for sliding in */
  display: none; /* Initially hidden */
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.drawer.showDrawer {
  display: block; /* Show the drawer */
}

/* css for draweer ends */

@supports (-moz-appearance: none) {
  .notificationNumberDiv {
    bottom: 23px;
  }
}
/* Css for animation */
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.notificationTextWrapper.show {
  /* animation: appear 350ms ease-in 1; */
  /* overflow: auto; */
}

/* @media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .bellIconWrapper img {
    width: 1.9vw;
    height: 1.9vw;
  }

  .notificationNumberDiv {
    width: 15px;
    height: 18px;
    position: absolute;
    top: -15%;
    left: 66%;
  }

  .notificationNumberDiv span {
    padding: 0 2px 0px;
    color: #fff;
    font-size: 1vw;
    font-weight: 800;
  }

  .notificationTextWrapper {
    position: absolute;
    top: 9%;
    left: 73%;
  }
} */

@media only screen and (min-width: 328px) and (max-width: 768px) {
  /* .bellIconWrapper {
    width: 4vw;
    height: 6vw;
    position: relative;
    transform: scale(0.5);
  } */
  .notifi-container {
    max-height: 89vh;
  }

  .notificationTextWrapper h3 {
    font-family: Nunito;
    font-size: 4.5vw;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #3384b1;
    margin: 0 0;
    padding: 5px 0;
  }

  .notificationDetails h2 {
    font-size: 4vw;
    margin-top: 0;
  }

  .notificationDetails p {
    font-size: 3vw;
  }

  .notificationDetails span {
    font-size: 3.5vw;
  }

  .notification-img {
    height: 12vw;
    width: 13vw;
    border-radius: 70px;
  }

  .profImgWrapper {
    height: 60px;
    width: 60px;
  }
  .profImgWrapper > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  #notifImg {
    /* transform: scale(0.58); */
    /* position: absolute; */
    /* top: -20px; */
    /* right: -39px; */
    width: unset !important;
    height: unset !important;
  }
  .notification-icon-wrapper {
    /* width: 100px !important; */
    /* height: 100px !important; */
    /* background-color: rgba(102, 163, 166, 1); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .notif-container {
    background-color: rgba(102, 163, 166, 1);
    width: 50px;
    height: 50px;
    padding: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notificationNumberDiv {
    position: absolute;
    top: 1px;
    left: 12px;
    border: 2px solid white;
    width: max-content;
    height: max-content;
  }
  .notificationNumberDiv span {
    font-size: 10px;
  }

  .notificationTextWrapper {
    overflow-x: hidden;
  }

  .notificationTextWrapper.show {
    width: 100vw;
    overflow-x: hidden;
    max-height: 162%;
    z-index: 22;
    left: 0;
    top: 0;
  }

  .notificationBar {
    background: #3384b1;
    background: #3384b1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 49px;
    padding-bottom: 25px;
  }

  .notificationClass {
    padding: 0px;
    gap: 1px;
  }

  .message-para {
    width: 100% !important;
  }

  .notificationDetails.showDrawer {
    width: 83%;
  }

  .notificationBar > svg {
    position: absolute;
    left: 28px;
    transform: scale(1);
  }

  #watch-svg {
    transform: scale(0.6);
  }

  #notifDots {
    transform: scale(0.7);
  }

  .messageDiv {
    justify-content: space-around;
  }

  .notifWatch {
    position: absolute;
    top: -15%;
  }

  .notifWatch + p {
    width: auto;
    font-size: 2.86vw;
    padding-left: 7%;
  }

  .messageDiv p:nth-child(2) {
    padding: 0;
  }

  .notificationTimeClass {
    gap: 0;
    position: relative;
  }

  .reason {
    font-size: 3vw !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .notificationTextWrapper {
    position: absolute;
    top: 9%;
    left: 22%;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s, max-height 0.5s;
    z-index: 1;
    width: 75vw;
  }
  .notificationDetails p {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 5px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 48vw;
    position: relative;
    font-weight: 600;
  }

  .notificationNumberDiv {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    bottom: 53%;
    left: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notificationNumberDiv span {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 720px) {
  .notificationClass {
    display: flex;
    gap: 20px;
    padding: 12px 10px;
    border-bottom: 1px solid #b3b3b3;
  }

  .messageDiv {
    width: 29vw;
    gap: 2vw;
  }

  .messageDiv p:nth-child(2) {
    width: 2rem;
    padding: 0 8px 0 0;
  }

  .notificationDetails span {
    font-family: Nunito;
    font-size: 13px;
    font-weight: 300;
    color: #b3b3b3;
    padding-right: 15px;
  }

  .notificationDetails p {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 5px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 21vw;
    position: relative;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .notificationTextWrapper {
    width: 40%;
  }
  .notificationNumberDiv {
    width: max-content;
    height: max-content;
    padding: 2px;
  }

  .notificationClass {
    display: flex;
    gap: 20px;
    padding: 12px 10px;
    border-bottom: 1px solid #b3b3b3;
  }

  .messageDiv {
    width: 29vw;
    gap: 2vw;
  }

  .messageDiv p:nth-child(2) {
    width: 2rem;
    padding: 0 8px 0 0;
  }

  .notificationDetails span {
    font-family: Nunito;
    font-size: 13px;
    font-weight: 300;
    color: #b3b3b3;
    padding-right: 15px;
  }

  .notificationDetails p {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 5px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 21vw;
    position: relative;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .notificationTextWrapper {
    width: 40%;
  }
  .notificationNumberDiv {
    width: max-content;
    height: max-content;
    padding: 2px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .notificationTextWrapper {
    width: 40%;
  }
  .notificationNumberDiv {
    width: max-content;
    height: max-content;
    padding: 2px;
  }
}
