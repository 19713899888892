@media only screen and (min-width: 328px) and (max-width: 768px) {
  #goLeftCustomer,
  #goRightCustomer {
    width: 7%;
    margin-top: 35%;
  }

  .ratings-content-section > .content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .ratings-content-section > .content {
    display: flex;
    align-items: center;
    width: 96%;
    flex-direction: row;
    gap: 0px;
  }

  .third-col > p {
    font-family: Nunito;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .customer-card {
    width: 27vw;
    height: 25vw;
  }

  .review-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70.8vw;
    height: 31.59vw;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .review-content {
    width: max-content;
    height: max-content;
    padding: 1rem 2rem;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  }
}
