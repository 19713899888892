.newsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.newsContainer > svg {
  position: absolute;
  top: 11px;
  right: 0px;
}

.newsDescriptionContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.newsDescriptionContainer > p {
  text-align: start;
  height: fit-content;
  font-size: 20px;
  font-family: "Nunito";
}

.newsImage {
  width: 102%;
  height: 45%;
}

.newsImage > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1023px) {
  .newsDescriptionContainer > p 
  {
    font-size: 14px;
  }
}
