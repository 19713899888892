.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 73vh;
    width: 100%;
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light grey border */
    border-top: 8px solid #3498db; /* Blue top border */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }