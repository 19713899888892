.login-header.login {
  width: 100%;
  margin: 0;
  height: 84px;
  /* background-color: #3384b1; */
  background-color: #3384b1;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-header.login > svg {
  position: absolute;
  top: 10px;
  right: 3%;
}

.email-verify-button {
  display: flex;
  gap: 0.25rem;
  align-content: center;
  justify-content: center;
}

.p-progress-spinner-circle {
  animation: 1.5s ease-in-out infinite, black 6s ease-in-out infinite;
  stroke: #ffffff;
}

.login-dialog-credentials-heading {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 79%; /* 28.44px */
  text-transform: capitalize;
}

input.login-username {
  width: 78%;
}

.user-login.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ul-label {
  display: block;
  width: 100%;
  padding-left: 9%;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
  line-height: 79%; /* 15.8px */
  text-transform: capitalize;
}

.ul-form {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
  align-items: center;
  justify-content: center;
}

.ul-form input {
  height: 51px;
  width: 30vw;
  border-radius: 10px;
  padding-left: 2%;
  background: #fff;
  border-style: none;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
}

.input-container {
  position: relative;
  display: inline-block;
}

.ul-icon-container {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.btn-container {
  display: flex;
  justify-content: center;
  margin-top: 53px;
}

.login-submit-btn {
  border-radius: 10px;
  background: #3384b1;
  width: 11vw;
  height: auto;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  padding: 2%;
  border-style: none;
}

#password + .iconDiv {
  position: absolute;
  top: 20px;
  right: 12px;
  cursor: pointer;
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .ul-label {
    display: block;
    width: 86%;
    padding-left: 0;
    color: #000;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    opacity: 1;
    line-height: 79%;
    text-transform: capitalize;
    font-family: Nunito;
  }

  .user-login.input-container > input {
    height: 51px;
    width: 71vw;
    font-size: 2.6vw;
    font-weight: 500;
  }

  .ul-label {
    font-size: 2.6vw;
  }

  .login-submit-btn {
    border-radius: 10px;
    background: #3384b1;
    width: 27vw;
    height: 7vw;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    border-style: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .login-header.login {
    width: 100%;
    height: 30px;
    padding: 3% 0;
  }

  .login-header.login > .login-dialog-credentials-heading {
    font-size: 21px;
  }

  .btn-container {
    margin-top: 16px;
  }

  #password + .iconDiv {
    position: absolute;
    top: -20px;
    right: -12px;
    cursor: pointer;
  }

  .ul-label {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    text-transform: capitalize;
    display: flex;

    width: 105%;
  }

  .login-submit-btn {
    border-radius: 10px;
    background: #3384b1;
    width: max-content;
    height: auto;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    padding: 0.5rem 1rem;
    border-style: none;
  }

  .ul-icon-container {
    position: absolute;
    top: 56%;
    right: 10%;
    transform: translateY(-50%);
  }

  .user-login.input-container {
    width: 96%;
    height: 13vw;
    border: none;
  }

  #username,
  #password {
    padding: 0;
    width: 100%;
    width: 100%;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    text-transform: capitalize;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .btn-container {
    padding: 1.5rem 0rem;
    margin: unset;
  }
}
