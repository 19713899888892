.calDiv {
  background: rgb(242, 250, 251);
  height: 84vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
}

.scheduleCalendar {
  width: 70%;
  padding: 3% 30px 2% 30px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
