.setupDetailsForm {
  display: grid;
  grid-template-columns: 47% 47%;
  column-gap: 20px;
  row-gap: 5px;
}

.datePicker,
.timePicker,
.facilitiesPicker,
.durationField,
.descriptionField > div {
  width: 100%;
}

.facilitiesPicker > .MuiFormControl-root > label {
  display: none !important;
}

.css-1balzp0-MuiFormControl-root .MuiInputLabel-root {
  display: none;
}
.descriptionField {
  width: 90%;
}

.durationField > div {
  width: 100%;
}

.setupLabel {
  font-weight: 500;
  font-family: "Nunito";
}

.setupLabel > MuiFormControl-root > label {
  display: none !important;
}
/* .QbRightSection{
    overflow-y: scroll;
} */

.tempSetupStyle {
  gap: 1em !important;
}

.MuiInputLabel-root.Mui-focused {
  display: none;
}

@media only screen and (max-width: 900px) {
  .tablet-setupContent {
    width: 90%;
    max-height: 39%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .basicDeatilsForm {
    width: 90%;
    column-gap: 10px;
    row-gap: 10px;
  }

  .setupDetailsForm {
    grid-template-columns: 50% 50%;
    column-gap: 15px !important;
    row-gap: 20px !important;
  }

  .descriptionField > div {
    width: 100%;
  }

  .datePicker,
  .timePicker,
  .facilitiesPicker,
  .durationField {
    width: 90%;
  }

  .descriptionField > .inputContainer > div {
    width: 97% !important;
  }
}

@media only screen and (max-width: 730px) {
  .datePicker,
  .timePicker,
  .facilitiesPicker,
  .durationField,
  .descriptionField > div {
    width: 95%;
  }

  .setupDetailsForm {
    grid-template-columns: 100% !important;
  }
}
