.book-stats {
  width: 68%;
  height: 152px;
  border-radius: 10px;
  border: 1px solid #3384b1;
  /* margin-left: 55px; */
  /* margin-top: 27px; */
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  /* padding: 3rem; */
}

.reschedule-booking-header {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 600;
  line-height: 22.12px;
  text-align: right;
}
.cbs-mr-root {
  display: flex;
  max-height: 100vh;
  flex-direction: column;
  overflow: hidden;
}

.allbookings {
  width: 350px;
}

.stats-section {
  display: flex;
  padding: 1.5rem 3rem;
  /* margin-bottom: 2vw; */
}

.book-heading-1 {
  color: #3384b1;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 9px;
}

.book-stats-1 {
  display: flex;
  justify-content: center;
  gap: 3em;
  justify-content: center;
  gap: 3em;
  border-right: 1px solid rgb(0, 0, 0, 0.5);
}
.rbc-month-view,
.rbc-calendar {
  background-color: #ffffff;
}

.overall-section {
  width: 100%;
  overflow-y: auto;
  max-height: 82vh;
}

.book-stats-1-1 {
  display: flex;
  flex-direction: column;
}

.stat-number {
  font-family: Nunito;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.sub-script {
  color: rgba(0, 0, 0, 0.5);
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.weekly-bookings {
  width: 50%;
}

.sub-script-successful {
  color: #46d939;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.rbc-btn-group button {
  width: 107px;
}

.sub-script-cancelled {
  color: #ed2626;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.book-stats-2 {
  display: flex;
  justify-content: center;
  /* gap: 3em; */
  justify-content: center;
  /* gap: 3em; */
  /* border-right: 1px solid rgb(0, 0, 0, 0.5); */
}

.book-stats-2-1 {
  display: flex;
  flex-direction: column;
  /* width: 388px; */
  padding-right: 4rem;
  border-right: 1px solid rgb(0, 0, 0, 0.5);
}

.book-stats-2-2 {
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  /* width: 388px; */
}

/* STATS INDICATOR  */

.stats-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;
  margin-left: 80px;
  gap: 40px;
}

.row-block {
  display: flex;
  gap: 40px;
}

.block {
  display: flex;
  gap: 9px;
}

.range-text {
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  width: 4vw;
}

.zeroTen.range-text {
  width: 59px;
}

.block-box {
  width: 33px;
  height: 45px;
}

.range-0-10 {
  background: rgba(0, 0, 0, 0.2);
}

.range-11-20 {
  background: #3384b1;
}

.range-21-30 {
  background: #fbce0e;
}

.range-31-40 {
  background: #ffb2b2;
}

/* TIMELINE SECTION  */

.timeline-heading {
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 50px;
  margin-bottom: -0.5vw;
}

.block > .block-box.range-0-10 > .range-text {
  width: 3vw;
}

.bookings-timeline {
  margin-bottom: 2vw;
}

.tl-head-span {
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.4;
}

.container.reservation-form {
  display: flex;
  padding: 0rem 3rem;
  justify-content: space-between;
}

.tl-container {
  /* margin-left: 50px; */
  width: 70%;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .bs-mob-container {
    min-height: 85vh;
    overflow-y: auto;
  }
  .myHistory.bs-mv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6vw;
    padding-bottom: 3vw;
  }

  .stats.bs-mv {
    width: 92vw;
    height: 29vw;
    border-radius: 5px;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .stats.bs-mv p {
    height: 36px;
    border-bottom: 1px solid black;
    width: 100%;
    color: #000;
    font-family: Nunito;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .numbers.bs-mv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 82%;
    gap: 2em;
  }

  .bs-mv-stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bs-mv.number.span {
    color: #000;
    font-family: Nunito;
    font-size: 7vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .bs-mv.status.span {
    color: rgba(0, 0, 0, 0.5);
    font-family: Nunito;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .bs.border-div {
    height: 50%;
    width: 1px;
    background: black;
    opacity: 0.5;
  }

  .bs-mv.bookings-timeline {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 5vw;
  }

  .mobileViewBookingStats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bs-mv.bookings-timeline {
    padding-top: 6vw !important;
    padding-bottom: 3vw !important;
  }

  .mobileViewBookingStats > .allbookings {
    border: solid 1px;
    width: 92vw;
    height: 29vw;
    border-radius: 5px;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .book-stats-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .book-stats-1 > div > span:nth-child(2) {
    font-size: 4.5vw;
  }

  .mobileViewBookingStats > .allbookings > .book-heading-1 {
    margin: 0px;
    border-bottom: 1px solid;
    font-size: 4.5vw;
    /* padding-top: 6vw; */
    border-top: 1px solid black;
  }
  .allbookings > .book-heading-1 {
    color: #3384b1 !important;
  }

  .mobileViewBookingStats > div > div > div {
    font-size: 20px;
  }

  .mobileViewBookingStats {
    padding: 0px !important;
  }

  .carouselDot {
    height: 2vw;
    width: 2vw;
    border-radius: 20px;
    border: 1px solid #3384b1;
  }

  .activeCarouselDot {
    height: 2vw;
    width: 2vw;
    border-radius: 20px;
    border: 1px solid #3384b1;
    background-color: #3384b1;
  }

  .statsCarousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
  }

  .displayCarousel {
    display: none;
  }

  .displayCarouselShow {
    display: flex;
  }

  .timeline-heading {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .tl-head-span {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .tl-container {
    margin: 0;
    width: 100%;
  }

  .days-container {
    display: flex;
    flex-direction: column;
    gap: 45px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 56px;
  }

  .days-container span {
    color: #000;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .time-slot-blocks {
    padding-left: 22vw;
  }

  .stats-indicator {
    flex-direction: row;
    padding-top: 4%;
    padding-left: 6%;
    display: flex;
    margin: 0;
    gap: 18%;
    margin-bottom: 4vw;
  }

  .range-text {
    font-size: 3vw;
    width: auto;
  }

  .block-box {
    width: 8vw;
  }

  .zeroTen.range-text {
    width: auto;
  }

  .range-text {
    font-weight: 600;
  }

  .block {
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cal-btn-container {
    display: flex;
    flex-direction: column;
    gap: 7vw;
    margin-bottom: 11vw;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .allbookings {
    width: 39vw;
    height: 14.5vw;
    border: 1px solid black;
    border-radius: 5px;
  }

  .overall-section {
    width: 86vw;
    overflow-y: auto;
    max-height: 90vh;
    overflow-x: hidden;
  }

  .stats-section {
    width: 100%;
    height: 17vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 0rem 3rem;
  }

  .tl-container {
    margin-left: 0;
    width: 95%;
  }

  .book-heading-1 {
    color: #000;
    font-family: Nunito;
    font-size: 2.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin: 3% 0 3% 0;
  }

  .stat-number {
    font-family: Nunito;
    font-size: 3.75vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .sub-script {
    color: rgba(0, 0, 0, 0.5);
    font-family: Nunito;
    font-size: 2.4vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .book-stats-1 {
    display: flex;
    gap: 3em;
    justify-content: center;
    gap: 3em;
    border-right: 1px solid rgb(0, 0, 0, 0.5);
    border-top: 1px solid;
  }

  .stats-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
    gap: 12px;
  }

  .weekly-bookings {
    width: 37vw;
  }

  .block-box {
    width: 11.5vw;
    height: 4.1vw;
  }

  .block {
    flex-direction: column;
    gap: 0;
  }

  .range-text {
    font-family: Nunito;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeline-heading {
    margin-left: 1.5vw;
  }

  .timeline-heading,
  .tl-head-span {
    font-size: 2.1vw;
    margin-bottom: 0;
  }

  .bookings-timeline {
    width: 102%;
  }

  .heading-calendar-container {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  .container.reservation-form {
    display: flex;
    justify-content: center;
  }

  .make-booking-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .make-booking-btn > button {
    width: 68.3vw;
    height: 6.36vw;
    font-family: "Nunito";
    background: #3384b1;
    color: white;
    border: none;
    font-size: 3vw;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .book-stats {
    width: 100%;
    height: max-content;
    padding: 4px;
  }

  .tl-container {
    /* margin-left: 46px;
    width: 58vw; */
  }

  .allbookings {
    width: 45%;
  }

  .weekly-bookings {
    width: 55%;
  }

  .book-heading-1 {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    /* margin-bottom: 9px;
    margin-top: 8px; */
    margin: unset !important;
  }

  .stat-number {
    font-family: Nunito;
    font-size: 16px;
  }

  .sub-script {
    color: rgba(0, 0, 0, 0.5);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    width: 9vw;
    text-align: center;
  }

  .book-stats-1 {
    gap: 0;
  }
  .bmrf {
    margin-left: unset;
  }
  .container.reservation-form {
    padding: unset !important;
  }
  .tl-container {
    width: 63%;
  }
  .book-stats-2-2 {
    padding-left: 2rem;
  }
  .book-stats-2-1 {
    padding-right: 2rem;
  }
  .overall-section {
    height: 100vh !important;
    min-height: unset !important;
    box-sizing: border-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 6vw;
  }
  .rbc-btn-group button {
    width: 25%;
    /* padding: 4px 8px !important;  */
  }

  .rbc-toolbar-label {
    flex-grow: unset !important;
    padding: unset;
  }
  .rbc-toolbar {
    display: flex !important;
    justify-content: space-around !important;
    margin: unset !important;
    padding: 0.5rem;
  }
  .rbc-toolbar button {
    padding: 4px 8px !important;
  }
  .rbc-calendar {
    margin-bottom: 2rem;
  }
  .rbc-btn-group {
    width: 35%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
