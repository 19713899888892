#toggleSideBar {
  transform: scale(0.7);
  width: 35px;
  height: 35px;
}

.nav-searchBar {
  width: 60vw;
  height: 9vw;
  position: relative;
}

.nav-searchBar input {
  padding: 2vw 0 2vw 5vw;
  aspect-ratio: 0;
}

.notif-prof-img {
  display: flex;
  gap: 8vw;
  align-items: center;
}

#searchIcon-nav {
  position: absolute;
  top: 20%;
  right: 0;
  transform: scale(1);
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .nav-searchBar {
    display: none;
  }
  .notif-prof-img {
    gap: 1rem;
  }
}
