.sidebar-container {
  height: 60vw;
  width: 120px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #3384b1;
}

a {
  text-decoration: none;
}

.sidebar-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 90px;
  gap: 31px;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 13px 0px 14px;
  border-radius: 0 10px 10px 0px;
  display: flex;
  gap: 6px;
}

.icon p {
  width: 90px;
}

.sb-active {
  word-wrap: break-word;
  width: 69px;
  text-align: center;
  color: #3384b1;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  margin: unset;
}
.sidebar-icon-container > a > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 17px 0;
  width: 112px;
}

.inactive {
  word-wrap: break-word;
  width: 69px;
  text-align: center;
  color: #fff;
  /* background-color: #3384b1; */
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  margin: unset;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .sidebar-container {
    height: 90vh;
    padding: 4px;
    width: 8%;
    overflow: hidden auto;
  }
  .icon {
    width: 81%;
    background-color: yellow;
    width: 81%;
    padding-top: 2px;
    padding-left: 6px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .icon svg {
    width: 100%;
  }

  .active,
  .inactive {
    font-size: 1vw;
  }
  .sidebar-icon-container > a {
    width: 100%;
  }
  .sidebar-icon-container > a > div {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }
  .sb-active,
  .inactive {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .sidebar-container {
    height: 99.8vh;
    width: 13vw;
  }

  .sidebar-icon-container {
    margin-top: 0;
  }
  .sideBarLogoContainer {
    width: 13vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-logo-image.tsidebar {
    height: 9vw;
    width: 6vw;
  }

  .nav-icon {
    width: 13vw;
    height: 11vw;
    background-color: #3384b1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16% 8% 16% 8%;
    width: 6vw;
  }
}

@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .sidebar-container {
    height: 80vh;
  }

  .sidebar-icon-container {
    height: 100%;
  }
}
