.rev.review-container {
  padding: 6px 14px 6px 14px;
  border: 1px solid #3384b1;
  width: 944px;
  height: 224px;
  border-radius: 10px;
}

.review-description {
  width: 100%;
}

.descNameField {
  font-family: Nunito;
  font-weight: bold;
  width: 45%;
}

.descColonField {
  display: flex;
  width: 10%;
}

.descValueField {
  width: 50%;
}

.reviewcontainer {
  border: 1px black solid;
  border-radius: 20px;
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  gap: 2%;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  font-family: Nunito;
}

.review-description-date {
  font-size: 20px;
  display: flex;
}

.review-image-description {
  display: flex;
  box-sizing: border-box;
  gap: 2%;
  width: 47vw;
}

.revieweditdelete {
}

.revieweditdelete > .edit {
  display: flex;
  flex-direction: column;
}

.review-description-text {
  font-size: 20px;
  display: flex;
}

.review-image {
  display: flex;
  align-items: center;
}

.review-description-name {
  font-size: 20px;
  display: flex;
}

.review-description > p {
  margin: 0px;
  margin-bottom: 5px;
}

.review-dp {
  width: 104px;
  height: 104px;
  border-radius: 55px;
}

.details-action {
  /* position: relative; */
}

.reviewer-details-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  position: relative;
}

.reviewer-name {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 79%; /* 18.96px */
  text-align: left;
}

.reviewer-name-date {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.reviewer-rating {
  display: flex;
  margin-top: 12px;
  justify-content: center;
}

.reviewer-review {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
  padding: 0%;
  word-wrap: break-word;
}

.reviewer-date {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 79%;
  opacity: 0.5;
}

.icon-text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.5;
}

.rev-icon-container {
  display: flex;
  gap: 1vw;
  position: absolute;
  top: 1%;
  right: 1%;
}

.rev-icon {
  display: flex;
  flex-direction: column;
}

.rev-icon.deleteReview {
  justify-content: center;
  align-items: center;
  display: none;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .rev.review-container {
    padding: 4%;
    border: 1px solid;
    width: 91%;
    height: 96%;
    border-radius: 10px;
    padding: 7vw 2vw 12vw 2vw;
  }

  .reviewcontainer {
    width: 90%;
  }

  .review-dp {
    width: 18vw;
    height: 18vw;
    border-radius: 55px;
  }

  .reviewer-name {
    color: #000;
    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: 79%;
    text-align: left;
  }

  .reviewer-date {
    font-size: 3vw;
  }

  .reviewer-rating {
    justify-content: flex-start;
  }

  /* .reviewer-rating svg {
    width: 21vw;
  } */

  .reviewer-review {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    padding: 0%;
    width: auto;
    line-height: normal;
  }

  .rev-icon-container {
    display: flex;
    gap: 5vw;
    position: absolute;
    left: 51vw;
    top: 1%;
  }

  .star-rating.reviewCard svg {
    font-size: 3.8vw;
}

  .rev-icon {
    position: absolute;
    right: -100px;
    display: flex;
    align-items: center;
  }

  .rev-icon svg {
    width: 4vw;
  }

  .icon-text {
    font-size: 3vw;
    display: none;
  }

  .details-and-action {
    display: flex;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .rev.review-container {
    padding: 6px 14px 6px 14px;
    border: 1px solid #3384b1;
    width: 81vw;
    height: 31vw;
    border-radius: 10px;
  }

  .review-description-name,
  .review-description-date,
  .review-description-text {
    font-size: 16px;
    display: flex;
  }

  .review-image-description {
    display: flex;
    box-sizing: border-box;
    gap: 2%;
    width: 58vw;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .review-image-description {
    width: 91%;
  }

  .review-description-name,
  .review-description-date,
  .review-description-text {
    font-size: 3.5vw;
  }
}
