.filterDialogWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
}

.filterHeaderWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2em;
}

.profileFormHeader {
  display: flex;
  background-color: #3384b1;
  border-radius: 0 0 20px 20px;
  justify-content: center;
  gap: 30px;
  padding: 10px 25px;
}
.closeDialogIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.formContent {
  background-color: #fff;
  display: flex;
  width: 17%;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  position: relative;
  padding: 1.5rem;
}

.fieldName {
  font-size: 18px;
  opacity: 0.5;
  font-weight: 100;
  color: #000000;
  font-weight: 500;
}
.fd-heading {
  font-family: "Nunito";
  color: #3384b1;
  font-weight: bold;
  font-size: 28px;
  text-decoration-line: underline;
  text-align: center;
  margin: unset;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 9px;
  /* opacity: 0; */
}

input[type="checkbox"] {
  height: 15px;
  width: 38px;
}

.fw-form {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 85px; */
  font-size: 29px;
  font-family: "Nunito";
  gap: 1rem;
}

.fw-filter.fieldName1 {
  width: 362px;
  height: 63px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  padding: 0px 14px;
  position: relative;
  border-radius: 5px;
}

#fw-filterByDate {
  width: 81%;
  margin-left: 17px;
  height: 98%;
  font-size: 26px;
  border: none;
  position: absolute;
  left: 49px;
  z-index: auto;
  font-family: Nunito;
  font-weight: 100;
}

#fw-filterByTime {
  border: none;
  width: 100%;
  height: 98%;
  z-index: auto;
  font-size: 26px;
}

#fw-filterByDate::-webkit-calendar-picker-indicator {
  opacity: 0;
}

#fw-filterByDate,
#fw-filterByTime {
  outline: none;
}

input#fw-filterByTime::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 9px;
  opacity: 0;
}

#fw-filterByDuration {
  height: 93%;
  width: 100%;
  border: none;
  font-size: 26px;
  font-family: Nunito;
  font-weight: 100;
}

#fw-filterByCapacity {
  height: 93%;
  width: 100%;
  border: none;
  font-size: 26px;
  font-family: Nunito;
  font-weight: 100;
}

.fw-search {
  width: 250px;
  height: 53px;
  border-radius: 10px;
  background: #3384b1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  color: #fff;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  margin-top: 1px;
  margin-left: 61px;
  margin-bottom: 41px;
}

label span {
  font-size: 26px;
  opacity: 0.5;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .formContent {
    width: 68%;
    height: 29%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fd-heading {
    font-size: 23px;
  }

  .closeDialogIcon {
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
  }

  label {
    display: flex;
    gap: 10px;
  }

  label span {
    font-size: 17px;
    opacity: 0.5;
  }

  input[type="checkbox"] {
    height: auto;
    width: auto;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .formContent {
    padding: 0;
    height: 27vw;
    width: 37%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterHeaderWrapper {
    width: 100%;
    height: 100%;
    padding-top: 2vw;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .fieldName {
    opacity: unset !important;
  }
}
