.margin-root {
  display: flex;
  flex-direction: column;
  background: #3384b1;
}

.navbarClass {
  height: 111px;
  display: flex;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.navbar_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0rem 1rem;
}

.nav-logo-image {
  height: 100%;
  width: 70%;
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 1))
    drop-shadow(0 0 30px rgba(255, 255, 255, 1))
    drop-shadow(0 0 30px rgba(255, 255, 255, 1));
  object-fit: contain;
}

.nav-icon {
  width: 119px;
  height: 111px;
  background-color: #3384b1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.navLogoContainer {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-incubator {
  width: 70%;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: 79%;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  padding-left: 27px;
  justify-content: space-between;
}

.nav-incubator > span {
  font-size: 28px;
  font-weight: bold;
  text-transform: capitalize;
}

.nav-notif-icon {
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  /* border-left: 1px solid #fff; */
}

.user-details > p {
  width: max-content;
  max-width: 40rem;
}
.nav-username {
  padding-left: 24px;
  color: #fff;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.nav-user-dp {
  width: 74px;
  height: 74px;
  border-radius: 50px;
}

.user-det-img {
  display: flex;
  align-items: center;
  gap: 12px;
}

.searchBar {
  display: flex;
  align-items: center;
  border: 1px solid #afafaf;
  padding: 8px;
  width: 285px;
  margin-right: 40px;
  border-radius: 37px;
  border: 1px solid #000;
}

input[type="search"] {
  width: -webkit-fill-available;
  height: 34px;
  border: none;
  font-size: 17px;
}

input[type="search"]:focus {
  outline: none;
}

/* @media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .navbarClass {
    height: 6vw;
  }

  .nav-icon {
    width: 4vw;
    height: auto;
    padding: 1%;
  }

  #navIconSvg {
    height: 23px;
    width: auto;
  }

  .navLogoContainer {
    width: 8vw;
  }

  .nav-logo-image {
    height: 3vw;
    width: auto;
    object-fit: fill;
  }

  .nav-incubator {
    width: 57%;
  }

  .nav-incubator > span {
    font-size: 1.28vw;
  }

  .searchBar {
    width: 14vw;
    height: 1vw;
  }

  input[type="search"] {
    height: 100%;
    font-size: 1vw;
  }

  .nav-notif-icon {
    width: 3vw;
    padding: 1%;
    border: none;
  }

  .nav-username {
    font-size: 1vw;
    padding-left: 1vw;
  }

  .nav-user-dp {
    width: 2.5vw;
    height: 2.5vw;
  }

  .user-det-img svg {
    width: 0.8vw;
  }
} */

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .nav-incubator {
    width: 44vw;
    text-align: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    display: flex;
    align-items: center;
    padding-left: 11px;
    justify-content: space-between;
  }

  .navbarClass {
    height: 11vw;
    /* width: 84.71vw; */
    background: rgba(102, 163, 166, 1);
    flex-grow: 0;
    flex-shrink: 0;
  }

  .navLogoContainer {
    width: 16%;
  }

  .nav-logo-image {
    height: 9vw;
    width: 9vw;
    object-fit: contain;
  }

  .nav-incubator {
    width: 48vw;
  }

  .nav-notif-icon {
    width: 11vw;
  }

  .user-details {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
    width: 16vw;
    padding-right: 6%;
    padding-left: 2%;
  }

  #notifImg {
    width: 5vw;
    height: 3.8vw;
  }
}
