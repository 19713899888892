.bookFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .bmrf {
    background-color: white;
    width: 449px;
    height: 90%;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .bmrf {
    background-color: white;
    width: 92vw;
    height: 78vh;
    margin: 0;
    padding-bottom: 3vw;
  }

  .mcb-textarea {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.5);
    font-family: Nunito;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    font-size: 3vw;
    height: 92px;
    padding: 0;
    padding: 3% 0 0 3%;
  }
  
  .bmrfFormFields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 85%;
    overflow-y: auto;
    gap: 3vw;
  }

  label {
    width: auto;
  }

  .makebold {
    font-size: 3.5vw;
    font-family: Nunito;
  }

  .bmrf-form input {
    width: 65vw;
    height: 32px;
    font-family: "Nunito";
    font-size: 14px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.5);
  }

  .form-sbt-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2vw;
  }

  .row4 {
    width: 318px;
    height: auto;
    font-family: "Nunito";
    font-size: 16px;
    border-radius: 3px;
    min-height: auto;
    color: rgba(0, 0, 0, 0.5);
  }

  .row3 {
    width: auto;
    height: auto;
    border: none;
    border-radius: none;
  }

  .row5 > textarea {
    width: 318px;
    height: 92px;
    border-radius: 2px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 0;
  }

  .form-sbt-btn > button {
    width: 36%;
    height: 42px;
    background: #3384b1;
    border: none;
    border-radius: 10px;
    font-size: 5vw;
    font-family: "Nunito";
    color: white;
  }

  .form-sbt-btn > button:nth-child(2) {
    background-color: white;
    color: #3384b1;
    border: 1px solid #3384b1;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 2px 9px;
  }
}

.makebold {
  font-weight: 600;
  color: black;
}
