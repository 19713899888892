.acceptedStatus {
  background-color: green;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}

.rejectedStatus {
  background-color: red;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}
.pendingStatus {
  background-color: orange;
  color: black;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
  font-family: "Poppins";
}
