.wallet-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 1.3rem 0rem;
}

.wallet-container > img {
  transform: scale(0.85);
}
.wallet-credits {
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 79%; /* 13.43px */
  text-transform: capitalize;
}
.wallet-credits > span {
  font-weight: 700;
}

.increment {
  cursor: pointer;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-shadow: 0px 1px 0px #000;
  filter: dropshadow(color=#000, offx=0px, offy=1px);

  /* -webkit-border-radius: 5px; */
}

.increment:active {
  -webkit-box-shadow: inset 0 1px 0 #ffe5c4, inset 0 -3px 0 #915100;
  -moz-box-shadow: inset 0 1px 0 #ffe5c4, inset 0 -3pxpx 0 #915100;
  box-shadow: inset 0 1px 0 #ffe5c4, inset 0 -3px 0 #915100;
}

.wallet-label {
  width: 443px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wallet-balance {
  width: 446px;
  border-style: none;
  border-bottom: 5px solid #3384b1;
  font-family: Inter;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 79%;
  text-transform: capitalize;
  margin-top: 1em;
}

input.wallet-balance:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.increment {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 79%; /* 14.22px */
  text-transform: capitalize;
  border: 1px solid black;
  width: 100px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.increment-buttons {
  display: flex;
  gap: 58px;
  margin-top: 29px;
  margin-left: 1em;
}

.wallet-add-balance {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  background: #3384b1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  margin-top: 2em;
  margin-left: 8em;
}

.wallet-hist.heading {
  color: #3384b1;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.individual-detail {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding-top: 5px;
  padding-bottom: 5px;
  /* gap: 16vw; */
  justify-content: space-between;
  padding-right: 10px;
}

.wallet-status {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.amt-added {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.currency {
  color: #f00;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.transaction-history {
  max-height: 200px;
  overflow-y: auto;
}

.transaction-history::-webkit-scrollbar-thumb {
  width: 10px; /* Set the width of the scrollbar */
  background: #000000; /* Set the background color */
}

.transaction-history::-webkit-scrollbar-track {
  background: blue;
}

.transaction-history::-webkit-scrollbar-button {
  display: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .wallet-label {
    width: 31vw;
  }

  .wallet-balance {
    width: 31vw;
  }

  .increment-buttons {
    display: flex;
    gap: 18%;
    margin-top: 1vw;
    margin-left: 1em;
  }

  .increment {
    font-family: Inter;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    text-transform: capitalize;
    border: 1px solid black;
    width: 6vw;
    height: 2vw;
    border-radius: 5px;
    display: flex;
    gap: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wallet-add-balance {
    width: max-content;
    height: max-content;
    border-radius: 10px;
    background: #3384b1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    margin-top: 1.5vw;
    margin-left: 7em;
    padding: 8px 10px;
  }

  .wallet-hist.heading {
    color: #3384b1;
    font-family: Inter;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .wallet-container {
    width: 92vw;
    flex-direction: column;
  }
  .walletImgWrapper img {
    width: 40%;
    height: 100%;
  }

  .walletImgWrapper {
    height: 37%;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;
  }

  .wallet-label {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wallet-balance {
    width: 84%;
    border-radius: 0;
    font-size: 4vw;
  }

  .increment-buttons {
    justify-content: center;
    gap: 2rem;
    margin: 0;
    width: 80%;
  }
  .wallet-col > p {
    text-align: center;
    font-weight: 600;
  }

  .increment {
    font-family: Inter;
    font-size: 3vw;
    line-height: 79%;
    text-transform: capitalize;
    border: 1px solid black;
    padding: 0.3vw;
    border-radius: 1vw;
    display: flex;
    gap: 1vw;
    width: 22vw;
    height: 6vw;
  }

  .wallet-add-balance {
    width: 27vw;
    height: 8vw;
    border-radius: 7px;
    background: #3384b1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    color: #fff;
    font-family: Inter;
    font-size: 3vw;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    margin: 0;
  }

  .wallet-credits {
    font-size: 18px;
    line-height: 24px;
  }

  .wallet-credits + svg {
    font-size: 3vw;
  }

  .wallet-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 95%;
    justify-content: center;
    align-items: center;
  }

  .add-balance-div {
    display: flex;
    justify-content: center;
  }

  .wallet-status {
    width: 24%;
  }

  .wallet-status,
  .amt-added {
    font-size: 3vw;
  }

  .transaction-history {
    padding: 0 4vw;
    width: 90%;
    max-height: 61vw;
    overflow-y: auto;
  }
  .wallet-hist.heading {
    margin: 0;
    font-size: 16px;
    font-family: "Nunito";
  }

  .wal-getDate,
  .wal-getTime {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .wallet-container > img {
    width: 33vw;
  }

  .wallet-balance {
    width: 94%;
    border-style: none;
    border-bottom: 5px solid #3384b1;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    text-transform: capitalize;
    margin-top: 1%;
  }

  .wallet-label {
    width: 94%;
  }

  .increment-buttons {
    display: flex;
    gap: 54px;
    margin-top: 3%;
    margin-left: 0;
  }

  .increment {
    font-family: Inter;
    font-size: 1.7vw;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    text-transform: capitalize;
    border: 1px solid black;
    width: 8vw;
    height: 3vw;
    border-radius: 5px;
    display: flex;
    gap: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wallet-add-balance {
    width: 18vw;
    height: 4vw;
    border-radius: 10px;
    background: #3384b1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    color: #fff;
    font-family: Inter;
    font-size: 1.7vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    margin-top: 7%;
    margin-left: 24%;
  }

  .wallet-status.actionType {
    width: 13%;
    display: flex;
    justify-content: start;
  }

  .wallet-status.transDate {
    width: 25%;
    display: flex;
    justify-content: start;
  }

  individual-detail {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 1vw;
    justify-content: space-between;
    padding-right: 10px;
  }

  .transTime {
    width: 20%;
  }

  .transAmount {
    width: 19%;
    display: flex;
    justify-content: end;
  }

  .wallet-hist.heading {
    color: #3384b1;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .transaction-history {
    max-height: 35vw;
    overflow-y: auto;
  }

  .rev.review-container {
    padding: 6px 14px 6px 14px;
    border: 1px solid #3384b1;
    width: 81vw;
    height: 31vw;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 720px) {
  .wallet-container > img {
    width: 36%;
  }
  .wallet-balance,
  .wallet-label {
    width: 79%;
  }

  .wallet-credits {
    font-size: 13px;
  }

  .wallet-balance {
    font-size: 19px;
  }

  .increment-buttons {
    gap: 12%;
    margin-left: 0;
  }

  .increment {
    font-size: 10px;
    gap: 4%;
    height: 23px;
    justify-content: center;
    line-height: 79%;
    width: 18%;
  }
}
