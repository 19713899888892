.selectAndDetailsContainer {
  width: 70%;
  margin-top: 5%;
}

.meetingRoomDetailsHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailsContainer {
  border: solid 1px black;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 20px;
  font-family: "Nunito";
  max-height: 12rem;
  overflow: auto;
}

.selectRoom {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: large;
  font-family: "Nunito";
}

.MuiInputLabel-root.Mui-focused {
  display: none;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  width: 100%;
}

.MuiPaper-elevation > ul {
  top: unset !important;
}

.css-13r88sr-MuiList-root-MuiMenu-list {
  top: unset !important;
}
@media only screen and (max-width: 900px) {
  .meetingRoomDetailsHeading > img {
    width: 70% !important;
    height: 1em;
  }

  .selectRoom {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: Medium;
    width: 100%;
  }
}
