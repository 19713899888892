@media only screen and (min-width: 328px) and (max-width: 768px) {
  .cal-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .rbc-calendar {
    height: 123vw !important;
    width: 96% !important;
  }

  .make-booking-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .make-booking-btn > button {
    width: 335px;
    height: 42px;
    border-radius: 10px;
    border: none;
    background: #3384b1;
    color: white;
    font-size: 4vw;
    font-family: "Nunito";
  }
}
.css-a29t2o-MuiFormControl-root {
  width: 100% !important;
}

.row4 {
  border: none !important;
}

.bmrf {
  position: relative; /* Needed for absolute positioning of the overlay */
}

.spinner-overlay {
  position: absolute; /* Positioning the overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black with some transparency */
  display: flex; /* Centering the loader */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 10; /* Ensure overlay is above the form */
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .rbc-calendar {
    height: auto !important;
  }

  .rbc-btn-group button {
    width: 62px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .cal-wrapper {
    width: 100%;
  }
}

.rbc-month-view {
  /* z-index: -1; */
}

/* .row3 {
  width: 90%;
  height: 51px;
  border: 1px solid;
  border-radius: 5px;
} */

.rbc-calendar {
  height: 66vh !important;
  position: relative;
  /* z-index: -1; */
}

.makebold {
  font-weight: 600;
}
