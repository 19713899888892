.p-multiselect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#dropDown-parent {
  width: 100%;
  font-size: 15px;
  font-family: Nunito;
  display: flex;
  justify-content: space-evenly;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #e5e7eb;
  color: #4b5563;
  border-radius: 16px;
}

.p-connected-overlay-enter-done {
  z-index: 1001;
  position: absolute;
  top: 689.906px;
  left: 1439.5px;
  min-width: 110.188px;
  transform-origin: center top;
  background: white;
  width: 372px;
}

.p-multiselect-items-wrapper {
  width: 100%;
}

.p-multiselect-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 9px;
  font-family: "Nunito";
}

.p-multiselect-item {
  display: flex;
  gap: 42px;
}

.p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
}
.bookingfacilitiesPicker {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.bookingfacilitiesPicker > .css-a29t2o-MuiFormControl-root {
  margin: 0px;
  width: 100% !important;
}
.bookingfacilitiesPicker > .css-a29t2o-MuiFormControl-root > div,
.bookingfacilitiesPicker > .css-a29t2o-MuiFormControl-root > div > div {
  width: 100% !important;
}

.bookingfacilitiesPicker > .css-sr37qz {
  margin: unset !important;
}
.bookingfacilitiesPicker > .css-a29t2o-MuiFormControl-root:active {
  border: solid 1px black;
}

.bookingfacilitiesPicker > .css-15fa390-MuiFormControl-root {
  height: 45px !important;
}

.MuiOutlinedInput-root {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .bookingfacilitiesPicker > .css-a29t2o-MuiFormControl-root {
    height: 40px;
  }
  .bookingfacilitiesPicker > .css-a29t2o-MuiFormControl-root > div {
    height: 100%;
  }
  .bookingfacilitiesPicker > div > div > div {
    padding: unset !important;
    height: 100%;
  }
  .bookingfacilitiesPicker,
  .bookingfacilitiesPicker > div,
  .bookingfacilitiesPicker > div > div,
  .bookingfacilitiesPicker > div > div > div {
    height: 100%;
    width: 100%;
  }
}
