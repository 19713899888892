@media only screen and (min-width: 328px) and (max-width: 768px) {
  .sidebarContainer {
    height: 100vh;
    width: 60%;
    background-color: #3384b1;
    position: fixed;
    top: 0;
    left: -60%; /* Sidebar hidden to the left */
    transition: left 0.3s ease-in-out; /* Transition for smooth sliding effect */
    z-index: 15;
  }

  .sidebarContainer.menuOpen {
    left: 0; /* Sidebar visible when menu is open */
  }

  .img-container {
    padding: 34px 129px 10px 16px;
  }

  .whiteborder {
    width: 100%;
    background-color: white;
    height: 1px;
  }

  .mSideBar-img-container {
    filter: drop-shadow(0 0 30px rgba(255, 255, 255, 1))
      drop-shadow(0 0 30px rgba(255, 255, 255, 1))
      drop-shadow(0 0 30px rgba(255, 255, 255, 1));
      display: flex;
      justify-content: center;
      margin-bottom: 2vw;
  }

  .mSideBar-img-container > img {
    object-fit: cover;
    width: 26%;
    height: 23%;
  }

  .hamBurgerIcon {
    padding: 22px 182px 33px 20px;
  }

  #svgContainer {
    display: flex;
    justify-content: end;
  }

  .hamBurgerIcon > button {
    width: 18vw;
    height: 7vw;
    border: none;
    color: #3384b1;
    background: white;
    border-radius: 5px;
    font-size: 3vw;
    font-family: "Nunito";
    font-weight: 500;
  }

  .iconSection {
    display: flex;
    align-items: center;
    gap: 1.5vw;
    padding-left: 4vw;
  }

  .mv-active {
    background: white;
  }

  .inactive {
    background: #3384b1;
  }

  .iconSection p {
    color: white;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .home p {
    color: #3384b1;
  }
}
