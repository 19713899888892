@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.letter {
  opacity: 0;
  display: inline-block;
  animation: fadeIn 0.5s forwards;
}

/* .home-heading {
  display: block;
  padding: 4% 0 0 4%;
  font-size: 44px;
  font-family: Poppins;
  color: white;
} */
