.coach-review-content {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-left: 110px;
}

.rating-single-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .okay {
  margin-right: 28px;
}

.three {
  margin-right: 24px;
}

.great {
  margin-right: 27px;
}

.good {
  margin-right: 24px;
} */

.awesome,
.great,
.good,
.okay,
.not-bad {
  text-align: right;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  width: max-content;
}

.rating.one {
  border: none;
}

.rating {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.ratingDigit {
  width: 7.5rem !important;
}
.rating {
  width: 2rem;
}
.rating-single-container {
  gap: 2rem;
}

.rating-single-container {
  width: 100%;
  position: relative;
}

.rating-img {
  width: 77%;
}

.ratingDigit {
  width: 20%;
  display: flex;
  justify-content: flex-start;
}

.rating-img > img {
  width: 100%;
}

.review-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: max-content;
  padding: 1rem;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.stepperManager {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  /* .mv-coach-review-content {
    width: 84%;
    height: AUTO;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */

  .mv.review.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    gap: 0.5rem;
    padding: 1rem;
  }

  .numbers {
    display: flex;
    flex-direction: column;
    gap: 2.7vw;
    font-family: "Nunito";
    font-weight: 100;
  }

  .numbers p {
    margin: 0;
  }

  .review-rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .stepperImages {
    display: flex;
    flex-direction: column;
    gap: 1.35rem;
  }

  #stepperImg {
    width: 93%;
    height: auto;
  }

  .awesome,
  .great,
  .good,
  .okay,
  .not-bad {
    font-family: Nunito;
    font-size: 16px;
    text-align: left;
    margin: 0;
  }
  .scales {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .awesomeCount,
  .greatCount,
  .goodCount,
  .okayCount,
  .notBadCount {
    font-size: 2.6vw;
  }

  .rating {
    font-size: 3vw;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .coach-review-content {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    margin-left: 1vw;
  }

  .awesome,
  .great,
  .good,
  .okay,
  .not-bad {
    text-align: right;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .rating-img > img {
    width: 31vw;
  }
  .rating-single-container {
    gap: 1rem;
  }
  .five > div,
  .four > div,
  .three > div,
  .two > div,
  .one > div,
  .three {
    margin: unset !important;
  }
  .ratingDigit {
    width: 5.5rem;
  }
  .rating {
    width: 2rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .rating-single-container {
    width: 100%;
    position: relative;
  }

  .rating-img {
    width: 65%;
  }

  .ratingDigit {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .rating-img > img {
    width: 100%;
  }

  .rating-figure {
    width: 10%;
    display: flex;
    justify-content: center;
  }

  /* .good {
    margin-right: 0;
  } */

  .three {
    margin-right: 0;
  }
}
