.otp-popUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
}

.code {
  border-radius: 6px;
  border: 2px solid #000;
  border-radius: 6px;
  font-size: 2vw;
  color: #3384b1;
  height: 2.5vw;
  width: 2vw;
  text-align: center;
}

.verify-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Nunito";
  align-items: center;
}

.code::-webkit-outer-spin-button,
.code::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.auth-header {
  width: 100%;
  height: 84px;
  border-radius: 20px 20px 0 0;
  background: #3384b1;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 39px;
  font-family: "Inter";
  color: white;
  font-weight: 500;
}

.svg-section svg {
  width: 4vw;
}

.auth-dialog-content {
  height: 100%;
  width: 100%;
  position: relative;
}

.auth-dialog-content > svg {
  position: absolute;
  top: 3%;
  right: 3%;
}

.box {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
  border-radius: 40px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset;
  height: 19vw;
  width: 37vw;
}

.rp-headerText {
  font-size: 20px;
  font-weight: 900;
  font-family: Poppins;
  margin-right: auto;
}

.svg-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-section + p {
  color: #000;
  font-family: Inter;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  margin-top: auto;
  line-height: normal;
  text-transform: capitalize;
  font-size: 2vw;
  text-align: center;
}

.otp-fields {
  margin-right: 0;
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  justify-content: center;
  align-items: center;
  height: 75%;
}

.auth-form-group {
  margin-bottom: 7px;
  display: flex;
  gap: 2.5vw;
}
.subtitle-backToLogin > a {
  text-decoration: none;
  color: red;
}

.auth-createPassword-btn > a {
  text-decoration: none;
  color: white;
}

.auth-createPassword-btn {
  border-radius: 5px;
  background: #ee5c03;
  font-family: Poppins;
  font-family: 12px;
  color: white;
  width: 346px;
  margin-top: 12px;
  padding: 16px 0px;
  border-style: none;
  border-radius: 5px;
}

.auth-subtitle-section {
  padding-top: 8px;
  padding-left: 132px;
}

.verify-button > button {
  border-radius: 10px;
  background: #3384b1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
  width: 12vw;
  height: 3vw;
  font-size: 1.3vw;
  color: #fff;
  font-family: Inter;
  /* font-size: 36px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border: none;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .box {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    /* box-shadow: 24; */
    border-radius: 40px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset;
    /* height: 35% !important; */
    width: 90%;
    background-color: #ffffff;
  }

  .auth-header {
    width: 100%;
    height: 53px;
    border-radius: 20px 20px 0 0;
    background: #3384b1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-family: "Nunito";
    color: white;
    font-weight: 500;
}

  .code {
    border-radius: 6px;
    border: 2px solid #3384b1;
    border-radius: 6px;
    font-size: 18px;
    color: #3384b1;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    box-sizing: border-box;
  }

  .verify-button > button {
    border-radius: 10px;
    background: #3384b1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
    width: max-content;
    height: max-content;
    padding: 8px;
    font-size: 14px;
    color: #fff;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    border: none;
  }

  .otp-fields {
    margin-right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: unset;
    padding: 5% 0;
  }

  .otp-fields > form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .auth-form-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .box {
    height: 26vw;
    width: 37vw;
  }
  .code {
    border: 2px solid #3384b1;
    border-radius: 6px;
    font-size: 2vw;
    color: #3384b1;
    height: 3.5vw;
    width: 2.5vw;
    text-align: center;
  }

  .verify-button > button {
    width: max-content;
    height: 4vw;
    font-size: 20px;
    padding: 0.25rem 1rem;
  }
  .auth-header {
    font-size: 32px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .box {
    height: 35%;
    width: 83%;
  }

  .auth-header {
    font-family: "Nunito";
  }

  .code {
    border-radius: 6px;
    border: 2px solid #3384b1;
    border-radius: 6px;
    font-size: 5vw;
    color: #3384b1;
    height: 8.5vw;
    width: 8vw;
    text-align: center;
  }
  .verify-button > button {
    border-radius: 10px;
    background: #3384b1;
    width: 27vw;
    height: 7vw;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    border-style: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .otp-fields {
    gap: 4vw;
  }
}
