.option p {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 1.3vw;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
  /* width: 100%; */
}

.option {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  padding-left: 1vw;
}

#logOutOption {
  width: 60%;
}

.one {
  border-bottom: 1px solid black;
}

.options-container {
  position: absolute;
  top: 10%;
  right: 1%;
  height: auto;
  width: 16vw;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .option svg {
    width: 30px;
  }

  .option p {
    font-size: 18px;
    width: -webkit-fill-available;
  }

  .options-container {
    padding: 0.5vw;
    position: absolute;
    top: 9%;
    right: 1%;
    height: max-content;
    width: 19%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  }
  .option {
    gap: 1rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .options-container {
    position: absolute;
    top: 10%;
    right: 4%;
    height: 12vw;
    width: 29vw;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    z-index: 1;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2rem;
    padding-left: 1vw;
    height: 50%;
  }

  .option svg {
    width: 2.7vw;
    height: 2.7vw;
  }

  .option p {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 2.6vw;
    font-weight: 300;
    line-height: 79%;
    text-transform: capitalize;
    width: auto;
  }

  .manage-acc {
    width: 36vw;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .options-container {
    padding: 1vw;
    position: absolute;
    width: 39vw;
    height: 16vw;
    right: 3%;
    top: 11%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  }

  .option svg {
    width: 4vw;
  }

  .option p {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 2.9vw;
    font-weight: 300;
    line-height: 79%;
    text-transform: capitalize;
  }
}
