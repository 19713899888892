.survey-form-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Nunito";
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  flex: 1;
}

.survey-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  flex: 1;
  background-color: #f0f4f8;
}

.survey-card {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.survey-card:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.survey-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.survey-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
